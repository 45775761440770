<template>
  <v-dialog
    v-model="isDialogVisible"
    max-width="900"
    persistent
    full-height
  >
    <!-- Dialog Activator -->
    <template #activator="{ on, attrs }">
      <a
        v-bind="attrs"
        v-on="on"
      >
        <slot>
          <v-icon
            size="18"
            class="me-2"
          >
            {{ icons.mdiAssistant }}
          </v-icon>
          <span>Create Plan Option</span>
        </slot>
      </a>
    </template>

    <!-- Dialog Content -->
    <claim-plan-selection-option-form
      v-if="ready"
      ref="formRef"
      :loading="loading"
      :claim-plan-options="planOptions"
      @close="onClose"
      @submit="onSubmit"
    >
    </claim-plan-selection-option-form>
  </v-dialog>
</template>

<script>
import { useNotifySuccess } from '@/composables'
import { mdiAssistant } from '@mdi/js'
import { ref } from '@vue/composition-api'
import useClaimPlan from '../../composables/useClaimPlan'
import { claimPlanSelectionOptionService } from '../../services'
import ClaimPlanSelectionOptionForm from './ClaimPlanSelectionOptionForm.vue'

export default {
  components: { ClaimPlanSelectionOptionForm },
  props: {
    selection: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isDialogVisible = ref(false)
    const loading = ref(false)
    const ready = ref(false)

    const onSubmit = async data => {
      loading.value = true

      console.log('data', [data])

      await claimPlanSelectionOptionService.store({
        ...data,
        selection_id: props.selection.id,
      }).finally(() => {
        loading.value = false
      })

      isDialogVisible.value = false

      emit('created')

      useNotifySuccess({ content: 'Plan Selection Option has been created successfully.' })
    }

    const onClose = () => {
      isDialogVisible.value = false
    }

    const { planOptions, loadClaimPlans } = useClaimPlan()

    loadClaimPlans({ distributor_id: props.selection.distributor_id }).then(() => {
      ready.value = true
    })

    return {
      loading,
      ready,
      isDialogVisible,

      planOptions,

      onSubmit,
      onClose,

      icons: {
        mdiAssistant,
      },
    }
  },
}
</script>
