import { ref, watch } from '@vue/composition-api'
import participantService from '../services/participant.service'

const userToLoadParticipant = ref([])
const loadedUserUuid = []
const allParticipants = ref([])
let timeout = null

export default () => {
  const forceAddUser = user => {
    userToLoadParticipant.value.push(user)
  }

  const addUser = user => {
    if (loadedUserUuid.indexOf(user.uuid) === -1) {
      forceAddUser(user)
    }
  }

  const list = (query = {}) => participantService.index(query).then(({ records, pagination }) => records)

  const loadUserParticipant = () => {
    const channel_user_ids = userToLoadParticipant.value.map(user => user.uuid)
    list({
      channel_user_ids,
      reusable: 1,
      take: 100,
    })
      .then(records => {
        loadedUserUuid.concat(channel_user_ids)
        records.forEach(record => {
          if (!allParticipants.value.find(r => r.uuid === record.uuid)) {
            allParticipants.value.push(record)
          }
        })
      })
      .finally(() => {
        timeout = null
      })
  }

  const findParticipantName = uuid => {
    const participant = allParticipants.value.find(p => p.uuid === uuid)

    return participant ? `${participant.first_name}, ${participant.last_name} (${participant.relationship})` : ''
  }

  watch(userToLoadParticipant, () => {
    if (!timeout) {
      timeout = setTimeout(loadUserParticipant, 100)
    }
  })

  return {
    addUser,
    forceAddUser,
    allParticipants,
    findParticipantName,
  }
}
