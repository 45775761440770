import { adminStore, currentUserStore } from '@/modules/admin/store'
import blog from '@/modules/blog/store'
import distributor from '@/modules/distributor/store.js'
import doctor from '@/modules/doctor/store.js'
import product from '@/modules/product/store'
import user from '@/modules/user/store'
import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import app from '../modules/app/store'
import common from '../modules/common/store'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    admin: adminStore,
    app,
    common,
    blog,
    appConfig: appConfigStoreModule,
    currentUser: currentUserStore,
    user,
    distributor,
    doctor,
    product,
  },
})
