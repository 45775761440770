<template>
  <v-form
    ref="formRef"
    @submit.prevent="onSubmit"
  >
    <v-card>
      <v-card-title class="text-sm-h5 d-flex justify-space-between">
        <slot name="title">
          Document
        </slot>

        <v-icon @click="onClose">
          {{ icons.mdiClose }}
        </v-icon>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-file-input
              v-model="form.file"
              label="File"
              outlined
              :rules="[validators.required]"
              :disabled="disabledFields.includes('file')"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          type="submit"
          color="success"
          :loading="loading"
          :disabled="loading"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { required } from '@core/utils/validation';
import { mdiClose } from '@mdi/js';
import { ref } from '@vue/composition-api';

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const form = ref({
      file: null,
    })

    const formRef = ref(null)

    const onSubmit = () => {
      if (formRef.value.validate()) {
        emit('submit', form.value)
      }
    }

    const onClose = () => {
      emit('close')
    }

    return {
      form,
      formRef,

      // Rules
      validators: {
        required,
      },

      onClose,
      onSubmit,

      icons: {
        mdiClose,
      },
    }
  },
}

</script>
