<template>
  <v-form
    ref="formRef"
    @submit.prevent="onSubmit"
  >
    <v-card>
      <v-card-title class="text-sm-h5 d-flex justify-space-between">
        <slot name="title">
          Resource
        </slot>

        <v-icon @click="onClose">
          {{ icons.mdiClose }}
        </v-icon>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <translation-locales />
          </v-col>
          <v-col cols="12">
            <h3 class="mb-4">
              Name
            </h3>
            <translatable-input
              v-model="form.dictionary.value"
              label="Item Name"
            >
            </translatable-input>
            <div
              v-for="(note, index) in form.notes"
              :key="note.type + index"
              class="ml-8 d-flex"
            >
              <v-select
                v-model="form.notes[index].type"
                :items="noteTypeOptions"
                item-value="value"
                item-text="title"
                style="max-width: 250px"
                class="mr-4"
              >
              </v-select>

              <translatable-input
                v-model="form.notes[index].content"
                label="Item Name"
                class="mr-4"
              >
              </translatable-input>

              <a
                class="mt-4"
                @click="destroyNoteType(form.notes[index])"
              >Remove</a>
            </div>
            <a
              v-if="nextNoteType"
              @click="addNote"
            >
              ADD NOTE +
            </a>
          </v-col>

          <v-col cols="12">
            <h3>Budget Target & Period</h3>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="form.max_quantity"
              class="mr-2"
              type="number"
              :suffix="form.max_quantity === 0 ? 'Unlimited' : ''"
              label="Max Quantity given a time period. 0=unlimited. "
            >
            </v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="form.max_total_per_quantity"
              type="number"
              :suffix="form.max_total_per_quantity === 0 ? 'Unlimited' : ''"
              label="Each Quantity cannot claim more than this total given a time period. 0=unlimited. "
            >
            </v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="form.max_reimbursement_rate"
              type="number"
              :step="0.05"
              label="Max Reimbursement Rate. 0.0 ~ 1.0"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <p>Budget is calculated per:</p>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="form.interval_count"
              type="number"
            >
            </v-text-field>
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="form.interval_unit"
              :items="intervalUnitOptions"
              item-value="value"
              item-text="title"
            >
            </v-select>
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="form.granted_to"
              label="Grant budget to:"
              :items="grantedToOptions"
              item-text="title"
              item-value="value"
            >
            </v-select>
          </v-col>

          <v-col cols="12">
            <h3 class="mb-4">
              Rule
            </h3>
            <v-switch
              v-model="form.require_alias"
              class="mr-8"
              label="(Optional) Require Alias. e.g. point to GP Diagnosis"
              outlined
            />
            <template v-if="form.require_alias">
              <v-autocomplete
                v-model="form.dictionary.alias_id"
                class="mt-4 mb-4"
                :items="dictionaryOptions"
                item-text="title"
                item-value="value"
                label="Alias e.g. alias to GP, submit an extra child from GP."
                outlined
                hide-details
                clearable
              ></v-autocomplete>
            </template>
          </v-col>

          <v-col cols="12">
            <h3 class="mb-4">
              Manifest & Display
            </h3>
            <v-text-field
              v-model="form.sort"
              type="number"
              label="Sort (ASC)"
            >
            </v-text-field>
            <div class="d-flex">
              <v-switch
                v-model="form.active"
                class="mr-8"
                label="Active"
                outlined
              />
              <v-switch
                v-model="form.manifest.show_balance"
                class="mr-8"
                label="Show Remaining Balance"
                outlined
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          type="submit"
          color="success"
          :loading="loading"
          :disabled="loading"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { TranslatableInput, TranslationLocales } from '@/components'
import { useTranslatable } from '@/composables'
import useDictionaries from '@/modules/site/views/dictionaries/useDictionaries'
import { t } from '@/plugins/i18n'
import { required } from '@core/utils/validation'
import { mdiClose } from '@mdi/js'
import { computed, ref, watch } from '@vue/composition-api'
import { claimPlanItemService } from '../../services'

export default {
  components: {
    TranslatableInput,
    TranslationLocales,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default: () => [],
    },
    resource: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const isEditing = ref(false)

    const useFieldValue = (fieldName, defaultValue) => (props.resource ? props.resource[fieldName] : defaultValue)

    const defaultFormValue = ({
      dictionary: useFieldValue('dictionary', {
        value: useTranslatable(),
        alias_id: null,
      }),
      notes: useFieldValue('notes', []),
      active: useFieldValue('active', false),
      sort: useFieldValue('sort', 99),
      max_quantity: useFieldValue('max_quantity', 0),
      max_total_per_quantity: useFieldValue('max_total_per_quantity', 0),
      max_reimbursement_rate: useFieldValue('max_reimbursement_rate', 0.9),
      require_alias: useFieldValue('require_alias', false),
      interval_count: useFieldValue('interval_count', 1),
      interval_unit: useFieldValue('interval_unit', 'day'),
      granted_to: useFieldValue('granted_to', 'participant'),
      manifest: useFieldValue('manifest', {
        show_balance: true,
      }),
    })

    //         'dictionary' => ['array'],
    //         'notes' => ['array',]

    const form = ref({
      ...defaultFormValue,
    })

    const formRef = ref(null)

    const onSubmit = () => {
      if (formRef.value.validate()) {
        console.log(form.value)

        emit('submit', form.value)
      }
    }

    const onClose = () => emit('close')

    const intervalUnitOptions = [
      { title: 'Day', value: 'day' },
      { title: 'Week', value: 'week' },
      { title: 'Month', value: 'month' },
      { title: 'Year', value: 'year' },
    ]

    const grantedToOptions = [
      { title: '(Family) Entire User Account', value: 'user' },
      { title: 'Per single participant', value: 'participant' },
    ]

    const noteTypeOptions = [
      // { title: 'Alias (GP Visit / ~tagging / ~groupping)', value: 'alias' },
      { title: 'Hint (Per visit)', value: 'hint' },
      { title: 'Subtitle (1 visit/day)', value: 'subtitle' },
    ]

    const destroyNoteType = note => {
      const index = form.value.notes.findIndex(n => n.type === note.type)

      if (props.resource) {
        claimPlanItemService.destroyNoteType(props.resource.id, note.type)
      }

      if (index >= 0) {
        form.value.notes.splice(index, 1)
      }
    }

    const nextNoteType = computed(() => noteTypeOptions.find(opt => !form.value.notes.find(note => note.type === opt.value)))

    const addNote = () => form.value.notes.push({
      type: nextNoteType.value.value,
      content: useTranslatable(),
    })

    const { dictionaryOptions, loadDictionaries } = useDictionaries()
    loadDictionaries({ take: 999, category: 'alias' })

    watch(computed(() => form.value.require_alias), value => {
      if (!value) {
        form.value.dictionary.alias_id = null
      }
    })

    return {
      isEditing,
      intervalUnitOptions,
      grantedToOptions,
      noteTypeOptions,
      destroyNoteType,
      dictionaryOptions,

      form,
      formRef,

      // Rules
      validators: {
        required,
      },

      t,
      onSubmit,
      onClose,
      nextNoteType,
      addNote,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>
