<template>
  <v-form ref="formElem">
    <v-card>
      <translation-locales />

      <v-card-text>
        <p>
          Adding child dictionary for distributor
          from <b>{{ resource.key }}</b>.
        </p>

        <v-autocomplete
          v-model="form.distributor_id"
          :items="distributorOptions"
          item-text="title"
          item-value="value"
          label="Select Distributor"
          outlined
          hide-details
          class="mt-5"
          :disabled="['alias'].includes(resource.category)"
        ></v-autocomplete>

        <translatable-input
          v-model="form.value"
          label="Value"
          class="mt-5"
        />

        <v-text-field
          v-model="form.notes"
          label="Notes"
          outlined
          class="mt-5"
        />
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { TranslatableInput, TranslationLocales } from '@/components';
import { useDistributor, useTranslatable } from '@/composables';
import { required } from '@core/utils/validation';
import { ref } from '@vue/composition-api';

export default {
  components: {
    TranslatableInput,
    TranslationLocales,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    // Get value from resource prop, use initValue if not exist
    const field = (name, initValue) => ({
      [name]: props.resource ? props.resource[name] : initValue,
    })

    const { distributorOptions } = useDistributor()

    const initialForm = {
      parent_id: props.resource.id,
      distributor_id: null,
      ...field('value', useTranslatable(null)),
      ...field('notes', null),
    }

    const form = ref({
      ...initialForm,
    })
    const formElem = ref(null)

    const validate = () => {
      if (formElem.value.validate()) emit('submit', form.value)
    }

    return {
      form,
      formElem,
      validate,
      required,
      distributorOptions,
    }
  },
}
</script>
