import { useIgnoreNullValue, useNotifyErrors } from '@/composables'
import { mdiMenuDown } from '@mdi/js'
import { computed, ref, watch } from '@vue/composition-api'
import { debounce } from 'lodash'

const defaultOptions = {
  page: 1,
  itemsPerPage: 10,
  sortBy: ['id'],
  sortDesc: [true],
}

const parseParams = params => {
  const { sorting = [], ...rest } = params

  let data = {
    ...rest,
  }

  sorting?.forEach(({ key, order }) => {
    data[`sort[${key}]`] = order
  })

  return useIgnoreNullValue(data)
}

export default function useTableList(service, filtersRef = null, userParams = {}) {
  const resource = ref(false) // current active resource
  const loading = ref(false)
  const tableRef = ref(null)

  const items = ref([])
  const itemsTotal = ref(0)
  const filters = filtersRef || ref({})
  const options = ref({
    ...defaultOptions,
    ...(userParams.options || {}),
  })
  const headerprops = ref({
    'sort-icon': mdiMenuDown,
  })

  const loadResource = async id => {
    resource.value = null

    return await service.show(id).then(data => {
      resource.value = data
      return data
    })
  }

  const tableOption = computed(() => {
    const { page, itemsPerPage, sortBy, sortDesc } = options.value

    const query = {
      page,
      take: itemsPerPage,
      sorting: [],
    }

    sortBy.forEach((key, index) => {
      const order = sortDesc[index] === true ? 'desc' : 'asc'

      query.sorting.push({
        key,
        order,
      })
    })

    return query
  })

  const paginate = async (query = {}) => {
    loading.value = true

    console.log('table', { ...tableOption.value })
    console.log('filters', { ...filters.value })
    console.log('filter ref', { ...filtersRef.value })
    console.log('query', { ...query })

    return service
      .index(
        parseParams({
          ...tableOption.value,
          ...filters.value,
          ...query,
        }),
      )
      .then(response => {
        const { records, pagination } = response
        items.value = records
        itemsTotal.value = pagination.total

        return response
      })
      .catch(err => {
        useNotifyErrors(err)

        return Promise.reject(err)
      })
      .finally(() => {
        loading.value = false
      })
  }

  watch(
    [options, filters],
    debounce(() => paginate(), 300),
  )

  if (userParams.auto || userParams.auto === undefined) {
    paginate()
  }

  return {
    resource,
    loadResource,
    loading,
    tableRef,
    headerprops,
    options,
    items,
    itemsTotal,
    paginate,
  }
}
