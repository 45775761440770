<template>
  <div id="team-view">
    <v-row>
      <!-- <v-col cols="12" md="5" lg="4"> -->
      <!-- <user-bio-panel :user="user" @openEditPhotoAside="setAside" v-if="user"></user-bio-panel> -->
      <!-- </v-col> -->

      <!-- <v-col cols="12" md="7" lg="8"> -->
      <v-col
        cols="12"
        md="12"
        lg="12"
      >
        <v-row>
          <v-col cols="12">
            <!-- <user-statistics :checkout-count="user.checkouts_count" :health-coin-count="user.reward_points_count" v-if="user"></user-statistics> -->
          </v-col>

          <v-col cols="12">
            <v-tabs
              v-model="userTab"
              show-arrows
              class="user-tabs"
            >
              <v-tab
                v-for="tab in tabs"
                :key="tab.icon"
              >
                <v-icon
                  size="20"
                  class="me-3"
                >
                  {{ tab.icon }}
                </v-icon>
                <span>{{ tab.title }}</span>
              </v-tab>
            </v-tabs>

            <v-tabs-items
              id="user-tabs-content"
              v-model="userTab"
              class="mt-5 pa-1"
            >
              <!-- <v-tab-item>
                <user-tab-checkout :user-id="user.id" v-if="user"></user-tab-checkout>
              </v-tab-item>
              <v-tab-item>
                <user-tab-reward-point :user-id="user.id" v-if="user"></user-tab-reward-point>
              </v-tab-item>
              <v-tab-item>
                <user-tab-employee-management :user="user" v-if="user"></user-tab-employee-management>
              </v-tab-item> -->

              <v-tab-item>
                <product-usage
                  v-if="teamUsage"
                  :user-id="teamUsage"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <team-avatar-aside
      v-if="aside"
      :id="user.id"
      v-model="aside"
      @changed="aside = false;"
    />
  </div>
</template>

<script>
import userStore from '@/modules/user/store'
import router from '@/router'
import store from '@/store'

import {
  defineAsyncComponent, onMounted, onUnmounted, ref,
} from '@vue/composition-api'

// eslint-disable-next-line object-curly-newline
import { mdiChartDonut } from '@mdi/js'
import useTeamView from '../../composables/useTeamView'
import TeamAvatarAside from './TeamAvatarAside.vue'

export default {
  components: {
    TeamAvatarAside,
    ProductUsage: defineAsyncComponent(() => import('@/modules/product/components/ProductUsage.vue')),
  },
  setup() {
    const USER_MODULE_NAME = 'team'
    const userTab = ref(null)

    const { teamUsage, loadTeamUsage } = useTeamView()

    const tabs = [{ icon: mdiChartDonut, title: 'Usage' }]

    // Register module
    if (!store.hasModule(USER_MODULE_NAME)) store.registerModule(USER_MODULE_NAME, userStore)

    onMounted(async () => {
      loadTeamUsage(router.currentRoute.params.id)
    })

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_MODULE_NAME)) store.unregisterModule(USER_MODULE_NAME)
    })

    const aside = ref(false)
    const setAside = () => {
      aside.value = true
    }

    return {
      tabs,
      userTab,
      setAside,
      aside,
      teamUsage,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
