var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "formRef",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSubmit.apply(null, arguments);
      }
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "text-sm-h5 d-flex justify-space-between"
  }, [_vm._t("title", function () {
    return [_vm._v(" Claim Plan Owner ")];
  }), _c('v-icon', {
    on: {
      "click": _vm.onClose
    }
  }, [_vm._v(" " + _vm._s(_vm.icons.mdiClose) + " ")])], 2), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "placeholder": "select user's relative(s)",
      "items": _vm.participants,
      "item-value": "uuid",
      "item-text": function itemText(item) {
        return _vm.getParticipantText(item);
      },
      "multiple": ""
    },
    model: {
      value: _vm.form.participant_uuids,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "participant_uuids", $$v);
      },
      expression: "form.participant_uuids"
    }
  })], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "type": "submit",
      "color": "success",
      "loading": _vm.loading,
      "disabled": _vm.loading
    }
  }, [_vm._v(" Confirm ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }