<template>
  <v-card>
    <v-data-table
      :headers="columns"
      :items="items"
      :options.sync="options"
      :server-items-length="itemsTotal"
      :loading="loading"
      :header-props="headerprops"
      class="table-section text-no-wrap"
    >
      <!-- ID -->
      <template #[`item.id`]="{ item }">
        {{ item.id }}
      </template>

      <!-- Active -->
      <template #[`item.active`]="{ item }">
        <v-chip
          :color="item.active ? 'success' : 'gray'"
          class="mr-4"
        >
          {{ item.active ? 'Active' : 'Inactive' }}
        </v-chip>
      </template>

      <!-- Name -->
      <template #[`item.name`]="{ item }">
        {{ t(item.name) }} ({{ item.actions.length }})
      </template>

      <!-- Distributor ID -->
      <template #[`item.distributor_id`]="{ item }">
        {{ item.distributor.nicename }}
      </template>

      <!-- Notes -->
      <template #[`item.notes`]="{ item }">
        {{ item.notes }}
      </template>

      <!-- Actions -->
      <template #[`item.actions`]="{ item }">
        <div class="d-flex align-center justify-center">
          <!-- dropdown -->
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                icon
                v-bind="attrs"
                v-on="on"
                @click="loadResource(item.id)"
              >
                <v-icon size="18">
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                link
                :to="{ name: 'flow-view', params: { id: item.id } }"
              >
                <v-list-item-title>
                  <v-icon
                    size="18"
                    class="me-2"
                  >
                    {{ icons.mdiEye }}
                  </v-icon>
                  <span>View</span>
                </v-list-item-title>
              </v-list-item>
              <template v-if="resource">
                <delete-dialog
                  v-if="$can('flow:delete', 'flow_management')"
                  :resource="resource"
                  @updated="paginate"
                />
              </template>
              <!-- Required to hold some space -->
              <v-list-item
                v-else
                style="min-width: 100px;"
              >
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { t } from '@/plugins/i18n';

import { mdiDotsVertical, mdiEye } from '@mdi/js';
import DeleteDialog from './DeleteDialog.vue';

export default {
  components: {
    DeleteDialog,
  },
  props: {
    tableList: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {
      loading, options, paginate, items, itemsTotal, headerprops, loadResource, resource,
    } = props.tableList

    const columns = [
      { text: 'ID', value: 'id' },
      {
        text: 'ACTIVE',
        value: 'active',
      },
      {
        text: 'NAME',
        value: 'name',
      },
      {
        text: 'DISTRIBUTOR',
        value: 'distributor_id',
      },
      {
        text: 'NOTES',
        value: 'notes',
      },
      {
        text: 'ACTIONS',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ]

    return {
      t,

      resource,
      loadResource,

      // Table config
      loading,
      paginate,
      options,
      items,
      itemsTotal,
      headerprops,

      columns,

      icons: {
        mdiDotsVertical,
        mdiEye,
      },
    }
  },
}
</script>
