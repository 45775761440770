<template>
  <v-dialog
    v-model="isDialogVisible"
    max-width="900"
    persistent
    full-height
  >
    <!-- Dialog Activator -->
    <template #activator="{ on, attrs }">
      <v-btn
        depressed
        dense
        v-bind="attrs"
        v-on="on"
      >
        <v-icon class="mr-1">
          {{ icons.mdiAccountEdit }}
        </v-icon>
        <span>Edit</span>
      </v-btn>
    </template>

    <!-- Dialog Content -->
    <user-form
      ref="formRef"
      :user="user"
      @close="onClose"
      @submit="updateUser"
    >
      <template #title>
        <span>Edit Admin User</span>
      </template>
    </user-form>
  </v-dialog>
</template>

<script>
import { useIgnoreNullValue, useNotifySuccess } from '@/composables'
import { mdiAccountEdit, mdiRefresh } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { userService } from '../../services'
import UserForm from './UserForm.vue'

export default {
  components: {
    UserForm,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const formRef = ref(null)
    const isDialogVisible = ref(false)

    const onClose = () => {
      isDialogVisible.value = false
    }

    const updateUser = async data => {
      try {
        await userService.update(props.user.id, useIgnoreNullValue(data))

        isDialogVisible.value = false

        emit('updated')

        useNotifySuccess({
          content: 'Admin user has been updated successfully.',
        })
      } catch (error) {
        console.log(error)
      }
    }

    return {
      formRef,
      isDialogVisible,

      onClose,
      updateUser,

      icons: {
        mdiAccountEdit,
        mdiRefresh,
      },
    }
  },
}
</script>
