<template>
  <div id="site-list">
    <v-row class="my-0 d-flex justify-end">
      <v-col
        cols="12"
        sm="2"
        style="margin-right:auto"
        align="right"
      >
        <div class="d-flex align-center">
          <v-select
            v-model="filters.category_in"
            class="mr-2"
            placeholder="Category"
            :items="categoryOptions"
            item-text="title"
            item-value="value"
            style="min-width: 200px;"
            hide-details
            single-line
            outlined
            chips
            multiple
            clearable
          ></v-select>

          <distributor-id-filter
            v-model="filters"
            chips
            clearable
            style="min-width: 200px;"
          />
          <!-- <v-select
            v-model="filters.distributor_id"
            class="mr-2"
            placeholder="Distributor"
            :items="distributorOptions"
            item-text="title"
            item-value="value"
            style="min-width: 150px;"
            chips
            clearable
          ></v-select> -->

          <v-btn
            color="primary"
            block
            class=""
            :loading="loading"
            :disabled="loading"
            @click="loadData"
          >
            <v-icon class="prefix-icon">
              {{ icons.mdiRefresh }}
            </v-icon>
            <span>
              {{ $t('common.list.refresh') }}
            </span>
          </v-btn>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="2"
        align="right"
      >
        <v-btn
          depressed
          block
          color="success"
          @click="setAside(null)"
        >
          <v-icon class="prefix-icon">
            {{ icons.mdiPlus }}
          </v-icon>
          {{ $t('common.list.create') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-card>
      <!-- table -->
      <v-data-table
        :headers="tableColumns"
        :items="tableItems"
        :options.sync="options"
        :server-items-length="tableTotal"
        :loading="loading"
        class="text-no-wrap"
        :header-props="headerprops"
      >
        <!-- ID -->
        <template #[`item.id`]="{ item }">
          #{{ item.id }}
        </template>

        <!-- name -->
        <template #[`item.name`]="{ item }">
          <span class="text-no-wrap">{{ t(item.name) }}</span>
        </template>

        <!-- value -->
        <template #[`item.value`]="{ item }">
          <div class="text-wrap dict-value">
            <b>{{ t(item.value) }}</b>
          </div>
          <div
            v-if="item.alias"
            class="text-wrap dict-value mt-2 ml-4"
          >
            <b> (alias) {{ t(item.alias.value) }}</b>
          </div>

          <v-row class="py-2">
            <v-col
              v-for="child in item.children"
              :key="child.id"
              :cols="item.children.length > 10 ? 6 : 12"
              class="text--secondary dict-value"
            >
              <v-icon @click="setAside(child)">
                {{ icons.mdiPencilOutline }}
              </v-icon>
              <span v-if="child.distributor"> ({{ child.distributor.slug }}) </span>
              <b> {{ t(child.value) }}</b>
            </v-col>
          </v-row>
        </template>

        <!-- distributor -->
        <template #[`item.distributor`]="{ item }">
          <span class="text-wrap">{{ item.distributor?.business_name }}</span>
        </template>

        <!-- notes -->
        <template #[`item.notes`]="{ item }">
          <span class="text-wrap">{{ item.notes }}</span>
        </template>

        <!-- active-->
        <template #[`item.active`]="{ item }">
          <span class="text-no-wrap">{{ item.active ? 'Yes' : 'No' }}</span>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <div class="d-flex align-center justify-center">
            <!-- dropdown -->
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="18">
                    {{ icons.mdiDotsVertical }}
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  link
                  @click="setAside(item)"
                >
                  <v-list-item-title>
                    <v-icon
                      size="18"
                      class="me-2"
                    >
                      {{ icons.mdiPencilOutline }}
                    </v-icon>
                    <span>Edit</span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="!item.distributor_id"
                  link
                  @click="setAside(item, 'store_child')"
                >
                  <v-list-item-title>
                    <v-icon
                      size="18"
                      class="me-2"
                    >
                      {{ icons.mdiPlus }}
                    </v-icon>
                    <span>Add Child</span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  link
                  @click="deleteDictionary(item)"
                >
                  <v-list-item-title>
                    <v-icon
                      size="18"
                      class="me-2"
                    >
                      {{ icons.mdiDeleteOutline }}
                    </v-icon>
                    <span>Delete</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <dictionary-aside
      v-if="aside === 'dictionary'"
      v-model="aside"
      :resource="resource"
      @changed="loadData(); aside = false"
    />

    <store-dictionary-child-aside
      v-if="aside === 'store_child'"
      v-model="aside"
      :resource="resource"
      @changed="loadData(); aside = false"
    />

    <!-- <form-aside
      v-if="aside"
      v-model="aside"
      :resource="resource"
      @changed="
        loadCards()
        aside = false
      "
    ></form-aside> -->
  </div>
</template>

<script>
import {
  mdiDeleteOutline, mdiDotsVertical,
  mdiMenuDown,
  mdiMenuUp,
  mdiPencilOutline,
  mdiPlus,
  mdiRefresh,
} from '@mdi/js'

import { DistributorIdFilter } from '@/components'
import { useDistributor } from '@/composables'
import ImportDialog from '@/modules/doctor/components/ImportDialog'
import { t } from '@/plugins/i18n'
import { formatDate } from '@core/utils/filter'
import { ref } from '@vue/composition-api'
import DictionaryAside from './dictionary-resource/DictionaryAside.vue'
import StoreDictionaryChildAside from './dictionary-resource/StoreDictionaryChildAside.vue'
import useDictionaries from './useDictionaries'

export default {
  components: {
    ImportDialog,
    DistributorIdFilter,
    DictionaryAside,
    StoreDictionaryChildAside,
  },
  setup() {
    const {
      tableItems, tableColumns, tableTotal, options, filters, loading, loadData, deleteDictionary,
    } = useDictionaries()

    const actions = ['Edit']

    const aside = ref('')
    const resource = ref(null)

    const setAside = (item, type = 'dictionary') => {
      aside.value = type
      resource.value = item
    }

    const singleSelect = ref(false)
    const headerprops = ref({
      'sort-icon': mdiMenuDown,
    })
    const showFilter = ref(true)

    const categoryOptions = [
      { title: 'app_required', value: 'app_required' },
      { title: 'receipt_type', value: 'receipt_type' },
      { title: 'claim_item_type', value: 'claim_item_type' },
      { title: 'alternative', value: 'alternative' },
      { title: 'alias', value: 'alias' },
    ]

    const { distributorOptions } = useDistributor()

    return {
      loading,
      t,
      formatDate,
      icons: {
        mdiDotsVertical,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiMenuDown,
        mdiMenuUp,
        mdiRefresh,
        mdiPlus,
      },

      // categories,
      setAside,
      aside,
      resource,

      tableColumns,
      tableItems,
      tableTotal,
      options,
      filters,

      actions,

      loadData,
      deleteDictionary,
      singleSelect,
      headerprops,
      showFilter,
      categoryOptions,
      distributorOptions,
    }
  },
}
</script>

<style lang="scss" scoped>
.dict-value {
  max-height: 45px;
  overflow: hidden;
}
</style>
