<template>
  <v-dialog
    v-model="isDialogVisible"
    max-width="800"
    persistent
    full-height
  >
    <template #activator="{ on, attrs }">
      <v-list-item
        link
        v-bind="attrs"
        v-on="on"
      >
        <v-list-item-title>
          <v-icon
            size="17"
            class="me-3"
          >
            {{ icons.mdiDelete }}
          </v-icon>
          <span>Delete</span>
        </v-list-item-title>
      </v-list-item>
    </template>

    <v-card>
      <v-card-title>Are you sure you want to delete this flow?</v-card-title>

      <v-card-text>
        <FlowDetails :flow="resource" />
      </v-card-text>

      <v-card-actions>
        <v-btn
          class="mr-4"
          :loading="loading"
          @click="onClose"
        >
          Back
        </v-btn>

        <v-btn
          class="mr-4"
          color="error"
          :loading="loading"
          @click="onConfirm"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiDelete, mdiRefresh } from '@mdi/js';
import { ref } from '@vue/composition-api';
import { flowService } from '../../services';
import FlowDetails from './FlowDetails.vue';

export default {
  components: {
    FlowDetails,
  },
  props: {
    resource: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const formRef = ref(null)
    const loading = ref(false)
    const isDialogVisible = ref(false)

    const onClose = () => {
      isDialogVisible.value = false
    }

    const onConfirm = async data => {
      loading.value = true

      await flowService.destroy(props.resource.id, {
        ...data,
      }).then(() => {
        isDialogVisible.value = false

        emit('updated')
      }).catch(error => {
        console.error(error)
      }).finally(() => {
        loading.value = false
      })
    }

    return {
      formRef,
      isDialogVisible,

      onClose,
      onConfirm,

      loading,

      icons: {
        mdiDelete,
        mdiRefresh,
      },
    }
  },
}
</script>
