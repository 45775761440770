import { useConfirm } from '@/composables'
import { computed, ref } from '@vue/composition-api'
import { claimPlanItemService, claimPlanOwnerParticipantService, claimPlanOwnerService, claimPlanService } from '../services'

export default () => {
  const plan = ref(null)
  const plans = ref([])

  const loadClaimPlan = id =>
    claimPlanService.show(id).then(data => {
      plan.value = data

      return data
    })

  const loadClaimPlans = query =>
    claimPlanService.index(query).then(({ records }) => {
      plans.value = records

      return records
    })

  const planOptions = computed(() => plans.value.map(p => ({ title: p.name.en, value: p.id })))

  const confirmDelete = async item => {
    await useConfirm({
      title: 'Confirm Delete',
      content: 'Unused plans can be deleted.',
    })

    return claimPlanService.destroy(item.id)
  }

  const confirmDeleteItem = async item => {
    await useConfirm({
      title: 'Confirm Delete Item',
      content: 'Unused Items can be deleted. Deleted items will not be visible.',
    })

    return claimPlanItemService.destroy(item.id)
  }

  const confirmDeleteOwner = async item => {
    await useConfirm({
      title: 'Confirm Delete Plan Owner',
      content: 'You may revoke user from using this plan. The revoked plan will be invisible to the user.',
    })

    return claimPlanOwnerService.destroy(item.id)
  }

  const confirmDeleteParticipant = async item => {
    await useConfirm({
      title: 'Confirm Delete Designated Participant',
      content: 'You may revoke participant right to use this plan.',
    })

    return claimPlanOwnerParticipantService.destroy(item.id)
  }

  const confirmDeletePlan = async p => {
    await useConfirm({
      title: 'Confirm Delete',
      content: 'Only unused plan can be deleted. If the plan is used, inactivate it instead.',
    })

    return claimPlanService.destroy(p.id)
  }

  const inactivateItem = async item => claimPlanItemService.update(item.id, { active: 0 })

  const activateItem = async item => claimPlanItemService.update(item.id, { active: 1 })

  const confirmAddParticipantToOwner = async (participant, owner) => {
    await useConfirm({
      title: 'Confirm add a Participant',
      content: 'Once added. The user can claim for this participant.',
    })

    return claimPlanOwnerService.update(owner.id, {
      participant_uuids: [participant.uuid],
    })
  }

  return {
    plan,
    plans,
    planOptions,
    loadClaimPlan,
    loadClaimPlans,
    confirmDelete,
    confirmDeleteItem,
    confirmDeleteOwner,
    confirmDeleteParticipant,
    inactivateItem,
    activateItem,
    confirmDeletePlan,
    confirmAddParticipantToOwner,
  }
}
