<template>
  <v-dialog
    v-model="isDialogVisible"
    max-width="800"
    persistent
    full-height
  >
    <!-- Dialog Activator -->
    <template #activator="{ on, attrs }">
      <v-btn
        color="success"
        outlined
        v-bind="attrs"
        v-on="on"
      >
        <v-icon
          size="17"
          class="me-1"
        >
          {{ icons.mdiPlus }}
        </v-icon>
        <span>Upload</span>
      </v-btn>
    </template>

    <!-- Dialog Content -->
    <document-form
      ref="formRef"
      :loading="loading"
      :disabled-fields="disabledFields"
      :upload-path="uploadPath"
      @close="onClose"
      @submit="onSubmit"
    />
  </v-dialog>
</template>

<script>
import { useNotifyErrors, useNotifySuccess } from '@/composables'
import { mdiPlus } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { documentService } from '../../services'
import DocumentForm from './DocumentForm.vue'

export default {
  components: {
    DocumentForm,
  },
  props: {
    uploadPath: {
      type: String,
      default: 'documents',
    },
    disabledFields: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const formRef = ref(null)
    const isDialogVisible = ref(false)
    const loading = ref(false)

    const onClose = () => {
      isDialogVisible.value = false
    }

    const onSubmit = async data => {
      try {
        loading.value = true

        await documentService.storeByPath(props.uploadPath, data)

        isDialogVisible.value = false

        emit('created')

        useNotifySuccess({
          content: 'Document has been created successfully.',
        })
      } catch (error) {
        useNotifyErrors(error)
      } finally {
        loading.value = false
      }
    }

    return {
      formRef,
      isDialogVisible,
      loading,

      onClose,
      onSubmit,

      icons: {
        mdiPlus,
      },
    }
  },
}
</script>
