import axios from '@axiosV2'

const index = params => axios.get('/admin/claim-plan-owner-participants', { params })
const show = id => axios.get(`/admin/claim-plan-owner-participants/${id}`)
const store = data => axios.post('/admin/claim-plan-owner-participants', data)
const update = (id, data) => axios.put(`/admin/claim-plan-owner-participants/${id}`, data)
const destroy = id => axios.delete(`/admin/claim-plan-owner-participants/${id}`)

export default {
  index,
  show,
  store,
  update,
  destroy,
}
