var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._l(_vm.userParticipants, function (participant) {
    return _c('div', {
      key: participant.uuid
    }, [_c('b', [_vm._v(_vm._s(participant.first_name))]), _vm._v(", " + _vm._s(participant.last_name) + " (" + _vm._s(participant.relationship) + ") "), _vm._l(participant.notes, function (note) {
      return _c('v-chip', {
        key: note.uuid,
        attrs: {
          "size": "sm"
        }
      }, [_vm._v(" " + _vm._s(note.type) + " ")]);
    }), _vm.owner && !_vm.owner.participants.find(function (p) {
      return p.participant_uuid === participant.uuid;
    }) ? _c('v-icon', {
      attrs: {
        "size": "16"
      },
      on: {
        "click": function click($event) {
          _vm.confirmAddParticipantToOwner(participant, _vm.owner).then(function () {
            return _vm.$emit('updated');
          });
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.icons.mdiPlus) + " ")]) : _vm._e()], 2);
  }), _c('create-participant-dialog', {
    key: _vm.user.uuid,
    attrs: {
      "user": _vm.user,
      "notes": [{
        type: 'pay_and_claim',
        snapshot: ['first_name', 'last_name', 'relationship']
      }]
    },
    on: {
      "created": function created($event) {
        _vm.forceAddUser(_vm.user);
        _vm.$emit('updated');
      }
    }
  }), !_vm.user.claim_plan_owners.find(function (owner) {
    return owner.claim_plan.uuid === _vm.plan.uuid;
  }) && _vm.userParticipants.length ? _c('create-dialog', {
    attrs: {
      "user": _vm.user,
      "plan": _vm.plan,
      "participants": _vm.userParticipants
    },
    on: {
      "updated": function updated($event) {
        return _vm.$emit('updated');
      }
    }
  }) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }