<template>
  <div>
    <filter-section :cols="[3, 3]">
      <search-filter
        slot="col_0"
        v-model="filters"
      />
      <v-chip
        slot="col_1"
        :color="filters.claim_plan_id_not ? 'success' : ''"
        @click="filters.claim_plan_id_not = filters.claim_plan_id_not ? null : plan.id; paginate()"
      >
        Filter No Plan User
      </v-chip>
    </filter-section>

    <user-plan-assignment-table
      ref="tableRef"
      :plan="plan"
      :table-list="tableList"
    />
  </div>
</template>

<script>
import { FilterSection, SearchFilter } from '@/components'
import { useParticipant, useTableList } from '@/composables'
import { userService } from '@/modules/claim/services'
import useUser from '@/modules/user/composables/useUser'
import { t } from '@/plugins/i18n'
import { formatDate } from '@core/utils/filter'
import { mdiDotsVertical, mdiTrashCan } from '@mdi/js'
import { onMounted } from '@vue/composition-api'
import UserPlanAssignmentTable from '../../components/plan/UserPlanAssignmentTable.vue'
import useClaimPlan from '../../composables/useClaimPlan'

export default {
  components: { SearchFilter, FilterSection, UserPlanAssignmentTable },
  props: {
    plan: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { filters } = useUser()
    const { confirmDeleteOwner, confirmDeleteParticipant } = useClaimPlan()
    const { findParticipantName } = useParticipant()
    filters.value.distributor_id = props.plan.distributor_id

    const tableList = useTableList(userService, filters)

    const {
      loading, tableRef, paginate, itemsTotal, items, options,
    } = tableList

    const columns = [
      { text: 'ID', value: 'id' },
      { text: 'User', value: 'email' },
      { text: 'Created At', value: 'created_at' },
      { text: 'Participants', value: 'participants', sortable: false },
      { text: 'Plans', value: 'plans', sortable: false },

      // { text: 'ACTIONS', value: 'actions', sortable: false },
    ]

    onMounted(paginate)

    return {
      confirmDeleteOwner,
      confirmDeleteParticipant,

      t,
      formatDate,
      filters,

      loading,
      tableList,
      tableRef,
      itemsTotal,
      items,
      columns,
      options,
      paginate,

      findParticipantName,

      icons: {
        mdiDotsVertical,
        mdiTrashCan,
      },
    }
  },
}
</script>
