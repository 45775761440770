var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-alert', {
    staticClass: "mb-2 mt-2",
    attrs: {
      "dense": "",
      "text": "",
      "type": "info"
    }
  }, [_vm._v(" Documents uploaded here are displayed "), _c('b', [_vm._v("during selection")]), _vm._v(" (comparing plans.) ")]), _c('create-dialog', {
    attrs: {
      "upload-path": "claim-plan-selections/".concat(_vm.selection.id, "/document")
    },
    on: {
      "created": function created($event) {
        return _vm.$emit('updated');
      }
    }
  }), _vm._l(_vm.selection.documents, function (doc) {
    return _c('div', {
      key: doc.id,
      staticClass: "mb-2 mt-2"
    }, [_c('span', {
      staticClass: "mr-2"
    }, [_vm._v("#" + _vm._s(doc.id) + " (" + _vm._s(doc.type) + ")")]), _c('a', {
      staticClass: "mr-2",
      attrs: {
        "href": doc.url,
        "target": "_blank",
        "download": ""
      }
    }, [_vm._v(_vm._s(doc.upload.original_filename))]), _vm.$can('claim_plan_selection_document:delete') ? _c('v-icon', {
      attrs: {
        "size": "16"
      },
      on: {
        "click": function click($event) {
          _vm.confirmDeleteDocument(doc).then(function () {
            return _vm.$emit('updated');
          });
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.icons.mdiTrashCan) + " ")]) : _vm._e()], 1);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }