<template>
  <v-card>
    <v-data-table
      :headers="columns"
      :items="items"
      :options.sync="options"
      :server-items-length="itemsTotal"
      :loading="loading"
      :header-props="headerprops"
      class="table-section text-no-wrap"
    >
      <template #[`item.user_id`]="{ item }">
        {{ item.user.email }}
      </template>

      <template #[`item.expires_at`]="{ item }">
        <span class="text-no-wrap">{{ formatDate(item.expires_at) }}</span>
      </template>

      <template #[`item.status`]="{ item }">
        <v-chip
          :color="item.status === 'completed' ? 'success' : 'gray'"
          class="text-no-wrap"
        >
          {{ item.status }}
        </v-chip>
      </template>

      <template #[`item.processed_actions`]="{ item }">
        <span class="text-no-wrap">{{ item.processed_actions.length }}</span>
      </template>

      <template #[`item.actions`]="{ item }">
        <div class="d-flex align-center justify-center">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                icon
                v-bind="attrs"
                v-on="on"
                @click="loadResource(item.id)"
              >
                <v-icon size="18">
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <template v-if="resource">
                <!-- <edit-dialog
                  v-if="$can('user_process:update', 'user_process_management')"
                  :resource="resource"
                  @updated="paginate"
                /> -->
                <delete-dialog
                  v-if="$can('user_process:delete', 'user_process_management')"
                  :resource="resource"
                  @updated="paginate"
                />
              </template>
              <v-list-item
                v-else
                style="min-width: 100px;"
              >
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { t } from '@/plugins/i18n';
import { formatDate } from '@core/utils/filter';
import { mdiDotsVertical } from '@mdi/js';

// import EditDialog from './EditDialog.vue'
import DeleteDialog from './DeleteDialog.vue';

export default {
  components: {
    // EditDialog,
    DeleteDialog,
  },
  props: {
    tableList: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {
      loading, options, paginate, items, itemsTotal, headerprops, loadResource, resource,
    } = props.tableList

    const columns = [
      { text: 'User ID', value: 'user_id' },
      { text: 'Expires At', value: 'expires_at' },
      { text: 'Status', value: 'status' },
      { text: 'Processed', value: 'processed_actions', sortable: false },
      {
        text: 'ACTIONS',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ]

    return {
      t,
      formatDate,
      resource,
      loadResource,
      loading,
      paginate,
      options,
      items,
      itemsTotal,
      headerprops,
      columns,
      icons: {
        mdiDotsVertical,
      },
    }
  },
}
</script>
