<template>
  <v-form
    ref="formRef"
    @submit.prevent="onSubmit"
  >
    <v-card>
      <v-card-title class="text-sm-h5">
        <slot name="title">
          Create User Role
        </slot>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col>
            <translation-locales />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
          >
            <v-text-field
              v-model="form.name"
              label="Key"
              :rules="[validators.required]"
              placeholder="e.g. hr_manager"
              outlined
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
          >
            <translatable-input
              v-model="form.name_translation"
              label="Name"
            />
          </v-col>
        </v-row>

        <permission-selection v-model="form.permissions" />
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="error"
          @click="onClose"
        >
          Close
        </v-btn>
        <v-btn
          type="submit"
          color="success"
          :loading="loading"
          :disabled="loading"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { TranslatableInput, TranslationLocales } from '@/components'
import { t } from '@/plugins/i18n'
import { required } from '@core/utils/validation'
import {
  mdiEyeOffOutline, mdiEyeOutline, mdiPlus, mdiRefresh,
} from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import PermissionSelection from './PermissionSelection.vue'

dayjs.extend(utc)

export default {
  components: {
    TranslationLocales,
    TranslatableInput,
    PermissionSelection,
  },
  props: {
    resource: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const isEditing = ref(false)

    const defaultFormValue = {
      name: null,
      name_translation: {},
      permissions: [],
    }

    const form = ref({
      ...defaultFormValue,
    })

    const formRef = ref(null)

    const parseRoleForEditting = resource => ({
      name: resource.name,
      name_translation: resource.name_translation,
      permissions: resource.permissions.map(permission => permission.id.toString()),
    })

    onMounted(() => {
      if (props.resource) {
        isEditing.value = true

        form.value = parseRoleForEditting(props.resource)
      }
    })

    const onClose = () => {
      emit('close')
    }

    const onSubmit = () => {
      if (formRef.value.validate()) {
        loading.value = true

        emit('submit', form.value)
      }
    }

    const resetForm = () => {
      if (isEditing.value) {
        formRef.value.resetValidation()
      } else {
        formRef.value.reset()

        Object.assign(form.value, defaultFormValue)
      }

      loading.value = false
    }

    return {
      loading,
      isEditing,

      form,
      formRef,

      // Rules
      validators: {
        required,
      },

      t,
      onClose,
      onSubmit,
      resetForm,

      icons: {
        mdiPlus,
        mdiEyeOffOutline,
        mdiEyeOutline,
        mdiRefresh,
      },
    }
  },
}
</script>
