<template>
  <div>
    <v-alert
      dense
      text
      type="info"
      class="mb-2 mt-2"
    >
      This Selection allows users to choose
      <b>{{ selection.min_option }}</b>
      <span v-if="selection.max_option > selection.min_option">to <b>{{ selection.max_option }}</b></span>
      Option(s)
    </v-alert>

    <create-dialog
      :selection="selection"
      @created="tableList.paginate"
    >
      <v-btn>
        <v-icon>
          {{ icons.mdiPlus }}
        </v-icon>
        Create Option
      </v-btn>
    </create-dialog>

    <claim-plan-selection-option-table
      ref="tableRef"
      class="mt-2"
      :table-list="tableList"
    />
  </div>
</template>

<script>
import { t } from '@/plugins/i18n'
import {
    mdiEye, mdiEyeOff, mdiPencil, mdiPlus,
} from '@mdi/js'

import { useTableList } from '@/composables'
import router from '@/router'
import { onMounted, ref } from '@vue/composition-api'
import ClaimPlanSelectionOptionTable from '../../components/plan-selection-option/ClaimPlanSelectionOptionTable.vue'
import CreateDialog from '../../components/plan-selection-option/CreateDialog.vue'
import { claimPlanSelectionOptionService } from '../../services'

export default {
  components: {
    ClaimPlanSelectionOptionTable,
    CreateDialog,
  },
  props: {
    selection: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const filters = ref({
      selection_id: router.currentRoute.params.id,
    })
    const tableList = useTableList(claimPlanSelectionOptionService, filters)

    onMounted(tableList.paginate)

    // const { tableRef, loading } = tableList

    return {
      tableList,
      t,

      icons: {
        mdiEye,
        mdiEyeOff,
        mdiPencil,
        mdiPlus,
      },
    }
  },
}

</script>
