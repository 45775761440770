<template>
  <div>
    <v-card v-if="selection">
      <div class="pl-4 pt-4">
        <router-link :to="{ name: 'claim-plan-selections' }">
          Back
        </router-link>
      </div>
      <v-card-title class="d-flex justify-space-between">
        <div
          class="d-flex align-center"
          style="min-width: 300px;"
        >
          <span class="mr-4">
            {{ t(selection.name) }}
          </span>

          <v-chip
            :color="selection.active ? 'success' : 'gray'"
            class="mr-4"
          >
            {{ selection.active ? 'Active' : 'Inactive' }}
          </v-chip>
        </div>

        <div>
          <EditDialog
            :resource="selection"
            @updated="reload"
          >
            <v-btn>
              <v-icon>
                {{ icons.mdiPencil }}
              </v-icon>
              Edit Selection
            </v-btn>
          </EditDialog>
        </div>
      </v-card-title>

      <v-card-text>
        <v-tabs>
          <v-tab>
            Options
          </v-tab>
          <v-tab>
            documents (<span>{{ selection.documents.length }}</span>)
          </v-tab>
          <v-tab>
            photos (<span>{{ selection.photos.length }}</span>)
          </v-tab>
          <v-tab-item>
            <claim-plan-selection-option-tab :selection="selection" />
          </v-tab-item>
          <v-tab-item>
            <claim-plan-selection-document-tab
              :selection="selection"
              @updated="reload"
            />
          </v-tab-item>
          <v-tab-item>
            <claim-plan-selection-photo-tab
              :selection="selection"
              @updated="reload"
            />
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { t } from '@/plugins/i18n'
import {
  mdiEye, mdiEyeOff, mdiPencil, mdiPlus,
} from '@mdi/js'

import router from '@/router'
import { onMounted } from '@vue/composition-api'
import EditDialog from '../../components/plan-selection/EditDialog.vue'
import useClaimPlanSelection from '../../composables/useClaimPlanSelection'
import ClaimPlanSelectionDocumentTab from './ClaimPlanSelectionDocumentTab.vue'
import ClaimPlanSelectionOptionTab from './ClaimPlanSelectionOptionTab.vue'
import ClaimPlanSelectionPhotoTab from './ClaimPlanSelectionPhotoTab.vue'

export default {
  components: {
    EditDialog,
    ClaimPlanSelectionOptionTab,
    ClaimPlanSelectionDocumentTab,
    ClaimPlanSelectionPhotoTab,
  },
  setup() {
    const { loadClaimPlanSelection, selection } = useClaimPlanSelection()

    const reload = () => loadClaimPlanSelection(router.currentRoute.params.id)

    onMounted(async () => {
      await reload()
    })

    return {
      selection,
      t,
      reload,

      icons: {
        mdiEye,
        mdiEyeOff,
        mdiPencil,
        mdiPlus,
      },
    }
  },
}

</script>
