var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "800",
      "persistent": "",
      "full-height": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "color": "success",
            "outlined": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          staticClass: "me-1",
          attrs: {
            "size": "17"
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiPlus) + " ")]), _c('span', [_vm._v("Create")])], 1)];
      }
    }]),
    model: {
      value: _vm.isDialogVisible,
      callback: function callback($$v) {
        _vm.isDialogVisible = $$v;
      },
      expression: "isDialogVisible"
    }
  }, [_c('flow-form', {
    ref: "formRef",
    attrs: {
      "loading": _vm.loading,
      "disabled-fields": _vm.disabledFields
    },
    on: {
      "close": _vm.onClose,
      "submit": _vm.onSubmit
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }