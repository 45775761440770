import { useConfirm } from '@/composables'
import { photoService } from '../services'

export default () => {
  const confirmDeletePhoto = async photo => {
    await useConfirm({
      title: 'Confirm Delete Photo',
      content: 'Deleted items will not be visible.',
    })

    return photoService.destroy(photo.id)
  }

  return {
    confirmDeletePhoto,
  }
}
