<template>
  <v-card>
    <v-data-table
      :headers="columns"
      :items="items"
      :options.sync="options"
      :server-items-length="itemsTotal"
      :loading="loading"
      :header-props="headerprops"
      class="table-section text-no-wrap"
    >
      <!-- Sort -->
      <template #[`item.sort`]="{ item }">
        {{ item.sort }}
      </template>
      <!-- actionable_id -->
      <template #[`item.actionable_id`]="{ item }">
        {{ t(item.actionable.name) }}
      </template>

      <!-- Active -->
      <template #[`item.active`]="{ item }">
        <v-chip
          :color="item.active ? 'success' : 'gray'"
          class="mr-4"
        >
          {{ item.active ? 'Active' : 'Inactive' }}
        </v-chip>
      </template>

      <!-- Notes -->
      <template #[`item.notes`]="{ item }">
        {{ item.notes }}
      </template>

      <!-- actions -->
      <template #[`item.actions`]="{ item }">
        <div class="d-flex align-center justify-center">
          <!-- dropdown -->
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                icon
                v-bind="attrs"
                v-on="on"
                @click="loadResource(item.id)"
              >
                <v-icon size="18">
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <template v-if="resource">
                <edit-dialog
                  v-if="$can('action:update', 'action_management')"
                  :resource="item"
                  @updated="paginate"
                />
                <delete-dialog
                  v-if="$can('action:delete', 'action_management')"
                  :resource="resource"
                  @updated="paginate"
                />
              </template>
              <!-- Required to hold some space -->
              <v-list-item
                v-else
                style="min-width: 100px;"
              >
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { t } from '@/plugins/i18n'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import { mdiDotsVertical } from '@mdi/js'
import DeleteDialog from './DeleteDialog.vue'
import EditDialog from './EditDialog.vue'

dayjs.extend(utc)

export default {
  components: {
    DeleteDialog,
    EditDialog,
  },
  props: {
    tableList: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {
      loading, options, paginate, items, itemsTotal, headerprops, loadResource, resource,
    } = props.tableList

    const columns = [
      { text: 'Sort', value: 'sort' },
      { text: 'Actionable ID', value: 'actionable_id' },
      { text: 'Active', value: 'active' },
      { text: 'Notes', value: 'notes' },
      {
        text: 'ACTIONS',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ]

    return {
      t,

      resource,
      loadResource,

      // Table config
      loading,
      paginate,
      options,
      items,
      itemsTotal,
      headerprops,

      columns,

      icons: {
        mdiDotsVertical,
      },
    }
  },
}
</script>
