import axios from '@axiosV2'

const index = params =>
  axios.get('/admin/flow-actions', {
    params,
  })
const show = id => axios.get(`/admin/flow-actions/${id}`)
const store = (type, data) => axios.post(`/admin/flow-actions/${type}`, data)
const update = (id, data) => axios.put(`/admin/flow-actions/${id}`, data)
const destroy = id => axios.delete(`/admin/flow-actions/${id}`)

export default {
  destroy,
  show,
  index,
  store,
  update,
}
