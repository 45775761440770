import axios from '@axiosV2'

const index = params => axios.get('/admin/participants', { params })
const show = uuid => axios.get(`/admin/participants/${uuid}`)
const store = data => axios.post('/admin/participants', data)
const update = (uuid, data) => axios.put(`/admin/participants/${uuid}`, data)
const destroy = uuid => axios.delete(`/admin/participants/${uuid}`)

export default {
  index,
  show,
  store,
  update,
  destroy,
}
