<template>
  <v-dialog
    v-model="isDialogVisible"
    max-width="800"
    persistent
    full-height
  >
    <!-- Dialog Activator -->
    <template #activator="{ on, attrs }">
      <v-list-item
        link
        v-bind="attrs"
        v-on="on"
      >
        <v-list-item-title>
          <v-icon
            size="17"
            class="me-3"
          >
            {{ icons.mdiDelete }}
          </v-icon>
          <span>Delete</span>
        </v-list-item-title>
      </v-list-item>
    </template>

    <!-- Dialog Content -->
    <v-card>
      <v-card-title>Are you sure you want to delete this action?</v-card-title>

      <v-card-text>
        <ActionDetails :action="resource" />
      </v-card-text>

      <v-card-actions>
        <v-btn
          class="mr-4"
          :loading="loading"
          @click="onClose"
        >
          Back
        </v-btn>

        <v-btn
          class="mr-4"
          color="error"
          :loading="loading"
          @click="onConfirm"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiDelete } from '@mdi/js';
import { ref } from '@vue/composition-api';
import { actionService } from '../../services';
import ActionDetails from './ActionDetails.vue';

export default {
  components: {
    ActionDetails,
  },
  props: {
    resource: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const formRef = ref(null)
    const loading = ref(false)
    const isDialogVisible = ref(false)

    const onClose = () => {
      isDialogVisible.value = false
    }

    const onConfirm = async data => {
      loading.value = true

      await actionService.destroy(props.resource.id, data).then(() => {
        isDialogVisible.value = false

        emit('updated')

        useNotifySuccess({
          content: 'Action has been deleted successfully.',
        })
      }).catch(error => {
        useNotifyErrors(error)
      }).finally(() => {
        loading.value = false
      })
    }

    return {
      formRef,
      isDialogVisible,

      onClose,
      onConfirm,

      loading,

      icons: {
        mdiDelete,
      },
    }
  },
}
</script>
