<template>
  <div>
    <v-alert
      dense
      text
      type="info"
      class="mb-2 mt-2"
    >
      Documents uploaded here are displayed <b>during selection</b> (comparing plans.)
    </v-alert>

    <create-dialog
      :upload-path="`claim-plan-selections/${selection.id}/document`"
      @created="$emit('updated')"
    />

    <div
      v-for="doc in selection.documents"
      :key="doc.id"
      class="mb-2 mt-2"
    >
      <span class="mr-2">#{{ doc.id }} ({{ doc.type }})</span>

      <a
        class="mr-2"
        :href="doc.url"
        target="_blank"
        download
      >{{ doc.upload.original_filename }}</a>

      <v-icon
        v-if="$can('claim_plan_selection_document:delete')"
        size="16"
        @click="confirmDeleteDocument(doc).then(() => $emit('updated'))"
      >
        {{ icons.mdiTrashCan }}
      </v-icon>
    </div>
  </div>
</template>

<script>
import { useDocument } from '@/composables';
import CreateDialog from '@/modules/media/components/document/CreateDialog.vue';
import { mdiTrashCan } from '@mdi/js';

export default {
  components: { CreateDialog },
  props: {
    selection: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { confirmDeleteDocument } = useDocument()

    return {
      confirmDeleteDocument,
      icons: {
        mdiTrashCan,
      },
    }
  },
}
</script>
