var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.selection ? _c('v-card', [_c('div', {
    staticClass: "pl-4 pt-4"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'claim-plan-selections'
      }
    }
  }, [_vm._v(" Back ")])], 1), _c('v-card-title', {
    staticClass: "d-flex justify-space-between"
  }, [_c('div', {
    staticClass: "d-flex align-center",
    staticStyle: {
      "min-width": "300px"
    }
  }, [_c('span', {
    staticClass: "mr-4"
  }, [_vm._v(" " + _vm._s(_vm.t(_vm.selection.name)) + " ")]), _c('v-chip', {
    staticClass: "mr-4",
    attrs: {
      "color": _vm.selection.active ? 'success' : 'gray'
    }
  }, [_vm._v(" " + _vm._s(_vm.selection.active ? 'Active' : 'Inactive') + " ")])], 1), _c('div', [_c('EditDialog', {
    attrs: {
      "resource": _vm.selection
    },
    on: {
      "updated": _vm.reload
    }
  }, [_c('v-btn', [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiPencil) + " ")]), _vm._v(" Edit Selection ")], 1)], 1)], 1)]), _c('v-card-text', [_c('v-tabs', [_c('v-tab', [_vm._v(" Options ")]), _c('v-tab', [_vm._v(" documents ("), _c('span', [_vm._v(_vm._s(_vm.selection.documents.length))]), _vm._v(") ")]), _c('v-tab', [_vm._v(" photos ("), _c('span', [_vm._v(_vm._s(_vm.selection.photos.length))]), _vm._v(") ")]), _c('v-tab-item', [_c('claim-plan-selection-option-tab', {
    attrs: {
      "selection": _vm.selection
    }
  })], 1), _c('v-tab-item', [_c('claim-plan-selection-document-tab', {
    attrs: {
      "selection": _vm.selection
    },
    on: {
      "updated": _vm.reload
    }
  })], 1), _c('v-tab-item', [_c('claim-plan-selection-photo-tab', {
    attrs: {
      "selection": _vm.selection
    },
    on: {
      "updated": _vm.reload
    }
  })], 1)], 1)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }