<template>
  <v-dialog
    v-model="isDialogVisible"
    max-width="900"
    persistent
    full-height
  >
    <!-- Dialog Activator -->
    <template #activator="{ on, attrs }">
      <a
        v-bind="attrs"
        v-on="on"
      >
        <slot>
          <v-icon
            size="18"
            class="me-2"
          >
            {{ icons.mdiAssistant }}
          </v-icon>
          <span>Assign Plan</span>
        </slot>
      </a>
    </template>

    <!-- Dialog Content -->
    <claim-plan-owner-form
      ref="formRef"
      :participants="participants"
      :loading="loading"
      :default-value="{
        participant_uuids: participants.filter(ps => ps.notes.find(n => n.type === 'pay_and_claim')).map(p => p.uuid)
      }"
      @close="onClose"
      @submit="onSubmit"
    >
    </claim-plan-owner-form>
  </v-dialog>
</template>

<script>
import { useNotifySuccess } from '@/composables'
import { mdiAssistant } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { claimPlanOwnerService } from '../../services'
import ClaimPlanOwnerForm from './ClaimPlanOwnerForm.vue'

export default {
  components: { ClaimPlanOwnerForm },
  props: {
    plan: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    participants: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isDialogVisible = ref(false)
    const loading = ref(false)

    const onSubmit = async data => {
      loading.value = true

      console.log('data', [data])

      await claimPlanOwnerService.store({
        ...data,
        user_id: props.user.id,
        claim_plan_id: props.plan.id,
      })

      isDialogVisible.value = false

      emit('updated')

      useNotifySuccess({ content: 'Plan Owner has been created successfully.' })
    }

    const onClose = () => {
      isDialogVisible.value = false
    }

    return {
      loading,
      isDialogVisible,

      onSubmit,
      onClose,

      icons: {
        mdiAssistant,
      },
    }
  },
}
</script>
