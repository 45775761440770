var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mt-4 mb-4"
  }, [_c('create-dialog', {
    attrs: {
      "claim-plan": _vm.plan
    },
    on: {
      "created": _vm.paginate
    }
  })], 1), _c('claim-plan-item-table', {
    ref: "tableRef",
    attrs: {
      "table-list": _vm.tableList
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }