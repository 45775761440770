<template>
  <v-form
    ref="formRef"
    @submit.prevent="onSubmit"
  >
    <v-card>
      <v-card-title class="text-sm-h5 d-flex justify-space-between">
        <slot name="title">
          Flow
        </slot>

        <v-icon @click="onClose">
          {{ icons.mdiClose }}
        </v-icon>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <translation-locales />
          </v-col>
          <v-col cols="12">
            <translatable-input
              v-model="form.name"
              label="Display Name"
            >
            </translatable-input>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="form.notes"
              label="Notes"
              outlined
              :rules="[validators.required]"
              :disabled="disabledFields.includes('notes')"
            />
          </v-col>
          <v-col cols="6">
            <v-autocomplete
              v-model="form.distributor_id"
              :items="distributorOptions"
              item-text="title"
              item-value="value"
              label="Distributor"
              outlined
              :disabled="disabledFields.includes('distributor_id') || resource"
            />
          </v-col>

          <v-col cols="6">
            <v-switch
              v-model="form.active"
              label="Active"
              outlined
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          type="submit"
          color="success"
          :loading="loading"
          :disabled="loading"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { TranslatableInput, TranslationLocales } from '@/components'
import { useDistributor, useTranslatable } from '@/composables'
import { t } from '@/plugins/i18n'
import { required } from '@core/utils/validation'
import { mdiClose } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  components: {
    TranslatableInput,
    TranslationLocales,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default: () => [],
    },
    defaultValue: {
      type: Object,
      default: () => {},
    },
    resource: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const isEditing = ref(false)

    const useFieldValue = (fieldName, defaultValue) => (props.resource ? props.resource[fieldName] : (props.defaultValue?.[fieldName] || defaultValue))

    const defaultFormValue = ({
      name: useFieldValue('name', useTranslatable()),
      notes: useFieldValue('notes', ''),
      distributor_id: useFieldValue('distributor_id', ''),
      active: useFieldValue('active', true),
    })

    const form = ref({
      ...defaultFormValue,
    })

    const formRef = ref(null)

    const { distributorOptions } = useDistributor()

    const onSubmit = () => {
      if (formRef.value.validate()) {
        emit('submit', form.value)
      }
    }

    const onClose = () => {
      emit('close')
    }

    return {
      isEditing,

      form,
      formRef,

      distributorOptions,

      validators: {
        required,
      },

      t,
      onSubmit,
      onClose,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>
