import { reactive, ref } from '@vue/composition-api'
import { userService } from '../services'

export default () => {
  const user = ref(null)
  const users = reactive([])
  const filters = ref({
    distributor_id: null,
    search: null,
    claim_plan_id_not: null,
  })

  const loadUser = id =>
    userService.show(id).then(data => {
      user.value = data

      return data
    })

  const loadUsers = params =>
    userService.index(params).then(records => {
      users.value = records

      return records
    })

  const confirmDelete = async user => {
    await useConfirm({
      title: 'Confirm Delete',
      content: 'You can create again. Only unused user can be deleted.',
    })

    await userService.destroy(user.id)
  }

  const inactivate = async user => {
    await userService.update(user.id, { active: 0 })
  }

  const activate = async user => {
    await userService.update(user.id, { active: 1 })
  }

  return {
    user,
    users,
    filters,
    loadUser,
    loadUsers,
    confirmDelete,
    inactivate,
    activate,
  }
}
