export default [
  {
    path: '/claim-plans',
    name: 'claim-plans',
    component: () => import('@/modules/claim/views/ClaimPlans.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/claim-plans/:id',
    name: 'claim-plan-view',
    component: () => import('@/modules/claim/views/ClaimPlanView/index.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/claim-plan-selections',
    name: 'claim-plan-selections',
    component: () => import('@/modules/claim/views/ClaimPlanSelections.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/claim-plan-selections/:id',
    name: 'claim-plan-selection-view',
    component: () => import('@/modules/claim/views/ClaimPlanSelectionView/index.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/claim-plan-items',
    name: 'claim-plan-items',
    component: () => import('@/modules/claim/views/ClaimPlanItems.vue'),
    meta: {
      layout: 'content',
    },
  },
]
