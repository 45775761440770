<template>
  <v-form
    ref="formRef"
    @submit.prevent="onSubmit"
  >
    <v-card>
      <v-card-title class="text-sm-h5 d-flex justify-space-between">
        <slot name="title">
          Action
        </slot>

        <v-icon @click="onClose">
          {{ icons.mdiClose }}
        </v-icon>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="form.sort"
              label="Sort"
              outlined
              type="number"
              :rules="[validators.required]"
              :disabled="disabledFields.includes('sort')"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="form.notes"
              label="Notes"
              outlined
              :rules="[validators.required]"
              :disabled="disabledFields.includes('notes')"
            />
          </v-col>
        </v-row>

        <v-row>
          <slot name="form-plugin"></slot>
        </v-row>

        <v-row>
          <v-col cols="6">
            <v-switch
              v-model="form.active"
              label="Active"
              outlined
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          type="submit"
          color="success"
          :loading="loading"
          :disabled="loading"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { required } from '@core/utils/validation';
import { mdiClose } from '@mdi/js';
import { ref } from '@vue/composition-api';

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default: () => [],
    },
    defaultValue: {
      type: Object,
      default: () => {},
    },
    resource: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const isEditing = ref(false)

    const useFieldValue = (fieldName, defaultValue) => (props.resource ? props.resource[fieldName] : (props.defaultValue?.[fieldName] || defaultValue))

    const defaultFormValue = {
      sort: useFieldValue('sort', 0),
      notes: useFieldValue('notes', ''),
      active: useFieldValue('active', false),
    }

    const form = ref({
      ...defaultFormValue,
    })

    const formRef = ref(null)

    const onSubmit = () => {
      if (formRef.value.validate()) {
        emit('submit', form.value)
      }
    }

    const onClose = () => {
      emit('close')
    }

    return {
      isEditing,

      form,
      formRef,

      // Rules
      validators: {
        required,
      },

      onSubmit,
      onClose,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>
