<template>
  <v-text-field
    v-model="searchInput"
    placeholder="Search by email"
    label="Search by email"
    @input="searchUser"
  >
  </v-text-field>
</template>

<script>
import { getUsers } from '@/api/user';
import { ref } from '@vue/composition-api';
import { debounce } from 'lodash';

export default {
  props: {
    take: {
      type: Number,
      default: 100,
    },
    text: {
      type: String,
      default: '',
    },
    query: {
      type: Object,
      default: () => {},
    },
  },

  setup(props, { emit }) {
    const searchInput = ref(props.text)

    const searchUser = debounce(async () => {
      const { data } = await getUsers({
        take: props.take,
        search: searchInput.value,
        ...props.query,
      })

      const users = data.data.records

      emit('users', users)
      emit(
        'options',
        users.map(u => ({ title: u.email, value: u.id })),
      )
      const filtered = users.filter(u => u.email === searchInput.value)
      if (filtered) {
        emit('selectParentUserById', filtered[0].id)
      }
    }, 300)

    if (props.query) searchUser()

    return {
      searchInput,
      searchUser,
    }
  },
}
</script>
