import axios from '@axios'

const store = async params => {
  const { status, data } = await axios.post(`/admin/medical/cards/import`, params, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  if (status === 200) {
    return data
  }

  return null
}

export default { store }
