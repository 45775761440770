<template>
  <div>
    <div class="mt-4 mb-4">
      <create-dialog
        :claim-plan="plan"
        @created="paginate"
      />
    </div>

    <claim-plan-item-table
      ref="tableRef"
      :table-list="tableList"
    />
  </div>
</template>

<script>
import { useTableList } from '@/composables'
import { t } from '@/plugins/i18n'
import { onMounted, ref } from '@vue/composition-api'
import ClaimPlanItemTable from '../../components/plan-item/ClaimPlanItemTable.vue'
import CreateDialog from '../../components/plan-item/CreateDialog.vue'
import { claimPlanItemService } from '../../services'

export default {
  components: {
    ClaimPlanItemTable,
    CreateDialog,
  },
  props: {
    plan: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const filters = ref({
      claim_plan_id: props.plan.id,
      distributor_id: null,
    })

    const tableList = useTableList(claimPlanItemService, filters, { auto: false })

    const { paginate } = tableList

    onMounted(paginate)

    return {
      t,
      tableList,
      paginate,
    }
  },
}

</script>
