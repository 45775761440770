<template>
  <div v-if="flow">
    <p>{{ t(flow.name) }}</p>
    <p>{{ flow.notes }}</p>
  </div>
</template>

<script>
import { t } from '@/plugins/i18n';

export default {
  props: {
    flow: {
      type: Object,
      default: () => null,
    },
  },
  setup() {
    return {
      t,
    }
  },
}
</script>
