export default [
  {
    path: '/flows',
    name: 'flows',
    component: () => import('@/modules/flow/views/FlowList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/flows/view/:id',
    name: 'flow-view',
    component: () => import('@/modules/flow/views/FlowView/index.vue'),
    meta: {
      layout: 'content',
    },
  },
]
