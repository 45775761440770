var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "formElem"
  }, [_c('v-card', [_c('translation-locales'), _c('v-card-text', [!_vm.resource ? _c('v-select', {
    staticClass: "mt-5",
    attrs: {
      "items": _vm.typeOptions,
      "item-text": "name",
      "item-value": "value",
      "label": "Select Type",
      "outlined": "",
      "hide-details": "",
      "disabled": !!_vm.resource
    },
    model: {
      value: _vm.form.category,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "category", $$v);
      },
      expression: "form.category"
    }
  }) : _vm._e(), _c('v-text-field', {
    staticClass: "mt-5",
    attrs: {
      "label": "Unique Key",
      "outlined": "",
      "rules": [_vm.required],
      "disabled": !!_vm.resource
    },
    model: {
      value: _vm.form.key,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "key", $$v);
      },
      expression: "form.key"
    }
  }), _vm.form.category !== 'alias' ? _c('v-autocomplete', {
    attrs: {
      "items": _vm.distributorOptions,
      "item-text": "title",
      "item-value": "value",
      "label": "Select Distributor",
      "outlined": "",
      "hide-details": "",
      "disabled": !!_vm.resource
    },
    model: {
      value: _vm.form.distributor_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "distributor_id", $$v);
      },
      expression: "form.distributor_id"
    }
  }) : _vm._e(), _vm.form.category !== 'alias' ? _c('v-autocomplete', {
    staticClass: "mt-4 mb-4",
    attrs: {
      "items": _vm.dictionaryOptions,
      "item-text": "title",
      "item-value": "value",
      "label": "(Optional) Alias e.g. alias to GP, submit an extra child from GP.",
      "outlined": "",
      "hide-details": "",
      "clearable": ""
    },
    model: {
      value: _vm.form.alias_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "alias_id", $$v);
      },
      expression: "form.alias_id"
    }
  }) : _vm._e(), _vm.useTranslatableEditor ? _c('translatable-editor', {
    staticClass: "mt-5",
    attrs: {
      "label": "Value",
      "allow-empty": ""
    },
    model: {
      value: _vm.form.value,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "value", $$v);
      },
      expression: "form.value"
    }
  }) : _c('translatable-input', {
    staticClass: "mt-5",
    attrs: {
      "label": "Value",
      "allow-empty": ""
    },
    model: {
      value: _vm.form.value,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "value", $$v);
      },
      expression: "form.value"
    }
  }), _c('v-text-field', {
    staticClass: "mt-5",
    attrs: {
      "label": "Notes",
      "outlined": ""
    },
    model: {
      value: _vm.form.notes,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "notes", $$v);
      },
      expression: "form.notes"
    }
  })], 1), _c('v-card-actions', {
    staticClass: "justify-center"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.validate
    }
  }, [_vm._v(" Submit ")])], 1), _vm._l(_vm.errors, function (msg) {
    return _c('v-alert', {
      key: msg,
      attrs: {
        "color": "warning"
      }
    }, [_vm._v(" " + _vm._s(msg) + " ")]);
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }