<template>
  <div class="mx-5">
    <!-- {{form}}
    {{blacklistData}} -->
    <v-row class="mt-5">
      <v-col
        v-for="(item, itemIndex) in form"
        :key="itemIndex"
        cols="12"
        sm="6"
      >
        <v-row>
          <v-col cols="4">
            <!-- <label v-if="itemIndex=='pnc_commission' && !isPayAndClaimEnabled"></label> -->
            <!-- <label v-else>{{ itemIndex }}</label> -->
            <label>{{ convertString(itemIndex) }}</label>
          </v-col>
          <v-col cols="8">
            <v-select
              v-if="itemIndex=='brands'"
              v-model="form[itemIndex]"
              :item-text="item => t(item.data.name)"
              item-value="data.id"
              outlined
              multiple
              :items="brandOptions"
            />
            <v-select
              v-else-if="itemIndex=='categories'"
              v-model="form[itemIndex]"
              :item-text="item => t(item.data.name)"
              item-value="data.id"
              outlined
              multiple
              :items="catOptions"
            />
            <!-- :item-text="item => t(item.data.name)" -->
            <v-select
              v-else-if="itemIndex=='channel_note_keys'"
              v-model="form[itemIndex]"
              :item-text="item => item.key"
              :item-value="item => item.key"
              outlined
              multiple
              :items="noteOptions"
            />
            <v-select
              v-else-if="itemIndex=='generate_strategy'"
              v-model="form[itemIndex]"
              item-text="name"
              item-value="value"
              outlined
              :items="generateStrategyOptions"
            />
            <v-select
              v-else-if="itemIndex=='can_accumulate' || itemIndex=='auto_distribute'"
              v-model="form[itemIndex]"
              item-text="name"
              item-value="value"
              outlined
              :items="boolOptions"
            />
            <v-text-field
              v-else-if="itemIndex=='core:order_report_email'"
              v-model="form[itemIndex]"
              item-text="name"
              item-value="value"
              multiple
              label="separate by comma (a@b.com,c@d.com)"
            />
            <v-text-field
              v-else-if="itemIndex=='core:report_password'"
              v-model="form[itemIndex]"
              item-text="name"
              item-value="value"
              multiple
              type="password"
              hint="protected export sheets; empty passwords still show as hased."
            />

            <!-- order_report_storage_driver
order_report_storage_target_url
order_report_storage_export_extension -->

            <!-- <div v-else-if="itemIndex=='pnc_commission'">
                <v-text-field v-if="isPayAndClaimEnabled" v-model="form[itemIndex]" outlined />
            </div> -->

            <v-text-field
              v-else
              v-model="form[itemIndex]"
              :disabled="itemIndex=='next_benefit_distribution_date'"
              outlined
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-if="type['core:order']"
        cols="12"
      >
        <ul>
          <b>xxxx_days_per_report</b>
          <li>7 = Send xxxx per week (7 Days)</li>
          <li>1 = Send xxxx every day</li>
          <li>0 = Don’t send xxxx at all.</li>
          <li>if there is no order, it still sends.</li>
        </ul>

        <ul>
          <b>xxxx_report_email</b>
          <li>Bcc receipients. Should test on UAT first,</li>
          <li>then set to internal email on PROD as final buffer</li>
          <li>check internal email's content.</li>
          <li>You can set it to external emails if content's correct.</li>
        </ul>
      </v-col>
      <v-col
        v-if="type['core:order'] || type['reward_point']"
        cols="12"
      >
        <ul>
          <b>Send to Blue SAS</b>
          <li>xxxx_report_storage_driver: azure_sas</li>
          <li>xxxx_report_storage_export_extension: json</li>
          <li>xxxx_report_storage_target_url: https://[[name]].blob.core.windows.net/[[container]]/[[file_name]]?[[token]]</li>
          <li>To fulfill trickier requirements, You may set target_url to static values instead of [[]]</li>
          <li>but [[file_name]]?[[token]] are always required in the target url.</li>
          <li>Leave them all empty = turn off.</li>
        </ul>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-right">
        <v-btn
          color="primary"
          @click="submit"
        >
          Save
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <VSnackbar
        v-model="success"
        top
        :timeout="3000"
        color="success"
      >
        Distributor Settings Updated.
      </VSnackbar>
      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-row>
  </div>
</template>

<script>
import { useExtractErrors } from '@/composables'
import { t } from '@/plugins/i18n'
import {
  fetchDistributorBlacklistSettings,
  fetchDistributorBrand,
  fetchDistributorCategory,
  fetchDistributorChannelNote,
  fetchDistributorCoreOrderSettings,
  fetchDistributorFinancialSettings,
  fetchDistributorInvoiceSettings,
  fetchDistributorMedicalCardSettings,
  fetchDistributorPricingSettings,
  fetchDistributorRewardPointSettings,
  fetchDistributorWhitelistSettings,
  storeDistributorBlacklistSettings,
  storeDistributorCoreOrderSettings,
  storeDistributorFinancialSettings,
  storeDistributorInvoiceSettings,
  storeDistributorMedicalCardSettings,
  storeDistributorPricingSettings,
  storeDistributorRewardPointSettings,
  storeDistributorWhitelistSettings,
  updateDistributorBlacklistSettings,
  updateDistributorCoreOrderSettings,
  updateDistributorFinancialSettings,
  updateDistributorInvoiceSettings,
  updateDistributorMedicalCardSettings,
  updateDistributorPricingSettings,
  updateDistributorRewardPointSettings,
  updateDistributorWhitelistSettings,
} from '@api/distributor/distributor'
import { mdiClose } from '@mdi/js'
import { computed, getCurrentInstance, onMounted, ref } from '@vue/composition-api'
import dayjs from 'dayjs'

export default {
  props: {
    categoryItems: {
      type: Array,
      required: true,
    },
    settings: {
      type: Array,
      required: true,
    },
    type: {
      type: Object,
      required: true,
    },
    distributorId: {
      type: Number,
      required: true,
    },
    distributorFeatures: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const errors = ref([])
    const success = ref(false)
    const instance = getCurrentInstance()

    const brandOptions = ref([])

    const blacklistData = ref(null)
    const whitelistData = ref(null)
    const rewardPointData = ref(null)
    const coreOrderData = ref(null)
    const medicalCardData = ref(null)
    const finanicalData = ref(null)
    const invoiceData = ref(null)
    const pricingData = ref(null)

    const fetchData = async () => {
      try {
        if (Object.keys(props.type)[0] === 'blacklist') {
          fetchDistributorBlacklistSettings(props.distributorId).then(res => {
            blacklistData.value = res.data?.data
            initialForm.value.forEach(element => {
              field(element, null)
            })
            instance.proxy.$forceUpdate()
          })

          return
        }
        if (Object.keys(props.type)[0] === 'whitelist') {
          fetchDistributorWhitelistSettings(props.distributorId).then(res => {
            whitelistData.value = res.data?.data
            initialForm.value.forEach(element => {
              field(element, null)
            })
            instance.proxy.$forceUpdate()
          })

          return
        }
        if (Object.keys(props.type)[0] === 'reward_point') {
          fetchDistributorRewardPointSettings(props.distributorId).then(res => {
            rewardPointData.value = res.data?.data
            initialForm.value.forEach(element => {
              if (element == 'reward_point_days_per_report') field(element, '0')
              else field(element, null)
            })
            console.log(initialForm.value)
            instance.proxy.$forceUpdate()
          })

          return
        }
        if (Object.keys(props.type)[0] === 'core:order') {
          fetchDistributorCoreOrderSettings(props.distributorId).then(res => {
            coreOrderData.value = res.data?.data
            initialForm.value.forEach(element => {
              if (element == 'core:order_days_per_report') field(element, '0')
              else if (element == 'core:order_report_email') array(element, '')
              else if (element == 'core:report_password') field(element, '')
              else field(element, null)
            })
            console.log(initialForm.value)
            instance.proxy.$forceUpdate()
          })

          return
        }
        if (Object.keys(props.type)[0] == 'medical_card') {
          fetchDistributorMedicalCardSettings(props.distributorId).then(res => {
            medicalCardData.value = res.data?.data

            initialForm.value.forEach(element => {
              if (element == 'generate_strategy') field(element, 'consecutive_digit')
              else if (element == 'name_to_medical_group') field(element, 'MixUp Solution Company Limited')
              else field(element, null)
            })
            instance.proxy.$forceUpdate()
          })
        }
        if (Object.keys(props.type)[0] == 'financial') {
          fetchDistributorFinancialSettings(props.distributorId).then(res => {
            finanicalData.value = res.data?.data

            initialForm.value.forEach(element => {
              field(element, null)
            })
            instance.proxy.$forceUpdate()
          })
        }
        if (Object.keys(props.type)[0] == 'invoice') {
          fetchDistributorInvoiceSettings(props.distributorId).then(res => {
            invoiceData.value = res.data?.data

            initialForm.value.forEach(element => {
              field(element, null)
            })
            instance.proxy.$forceUpdate()
          })
        }
        if (Object.keys(props.type)[0] == 'pricing') {
          fetchDistributorPricingSettings(props.distributorId).then(res => {
            pricingData.value = res.data?.data

            initialForm.value.forEach(element => {
              field(element, null)
            })
            instance.proxy.$forceUpdate()
          })
        }
      } catch (error) {}
    }

    onMounted(fetchData)

    fetchDistributorBrand().then(res => {
      brandOptions.value = res.data.data.records
    })
    const catOptions = ref([])
    fetchDistributorCategory().then(res => {
      catOptions.value = res.data.data.records
    })
    const noteOptions = ref([])
    fetchDistributorChannelNote().then(res => {
      noteOptions.value = res.data.data.records
    })

    const generateStrategyOptions = ref([
      {
        name: 'Consecutive Digit',
        value: 'consecutive_digit',
      },
      {
        name: 'Random String',
        value: 'random_string',
      },
    ])

    const boolOptions = ref([
      {
        name: 'True',
        value: true,
      },
      {
        name: 'False',
        value: false,
      },
    ])

    const type = ref(Object.keys(props.type)[0])

    const field = (name, initValue) => {
      if (type.value == 'blacklist') {
        form.value[name] = blacklistData.value ? blacklistData.value[name] : initValue
      }
      if (type.value == 'whitelist') {
        form.value[name] = whitelistData.value ? whitelistData.value[name] : initValue
      }
      if (type.value == 'medical_card') {
        form.value[name] = medicalCardData.value ? medicalCardData.value[name] : initValue
      }
      if (type.value == 'financial') {
        if (name === 'next_benefit_distribution_date') {
          form.value[name] = finanicalData.value ? dayjs(finanicalData.value[name]).format('YYYY-MM-DD HH:mm:ss') : initValue
        } else {
          form.value[name] = finanicalData.value ? finanicalData.value[name] : initValue
        }
      }
      if (type.value == 'invoice') {
        form.value[name] = invoiceData.value ? invoiceData.value[name] : initValue
      }

      if (type.value == 'pricing') {
        form.value[name] = pricingData.value ? pricingData.value[name] : initValue
      }

      if (type.value == 'core:order') {
        form.value[name] = coreOrderData.value ? coreOrderData.value[name] : initValue
      }
      if (type.value == 'reward_point') {
        form.value[name] = rewardPointData.value ? rewardPointData.value[name] : initValue
      }

      // if (false) {
      //   form.value[name] = props.resource ? props.resource[name] : initValue
      // }
    }

    const array = (name, initValue) => {
      if (name == 'core:order_report_email') {
        form.value[name] = coreOrderData.value ? coreOrderData.value[name].join(',') : initValue
      }
    }

    const convertString = input => {
      const modifiedInput = input.replace(/_/g, ' ')

      return modifiedInput.charAt(0).toUpperCase() + modifiedInput.slice(1)
    }

    const initialForm = ref([...props.categoryItems])

    const isPayAndClaimEnabled = computed(() => {
      if (props.distributorFeatures) {
        return props.distributorFeatures.some(feature => feature.key === 'enable_pay_and_claim')
      }

      return false
    })

    const form = ref({})

    const handleBlackList = async () => {
      loading.value = true

      const request = blacklistData.value
        ? updateDistributorBlacklistSettings(props.distributorId, {
            ...form.value,
          })
        : storeDistributorBlacklistSettings(props.distributorId, {
            ...form.value,
          })
      await request
        .then(async res => {
          success.value = true
          emit('changed', res.data.data)
          fetchData()
        })
        .catch(err => {
          errors.value = useExtractErrors(err)
        })
      loading.value = false
    }

    const handleWhiteList = async () => {
      loading.value = true

      const request = whitelistData.value
        ? updateDistributorWhitelistSettings(props.distributorId, {
            ...form.value,
          })
        : storeDistributorWhitelistSettings(props.distributorId, {
            ...form.value,
          })
      await request
        .then(async res => {
          success.value = true
          emit('changed', res.data.data)
          fetchData()
        })
        .catch(err => {
          errors.value = useExtractErrors(err)
        })
      loading.value = false
    }

    const handleMedicalCard = async () => {
      loading.value = true
      const request = medicalCardData.value
        ? updateDistributorMedicalCardSettings(props.distributorId, {
            ...form.value,
          })
        : storeDistributorMedicalCardSettings(props.distributorId, {
            ...form.value,
          })
      await request
        .then(async res => {
          success.value = true
          emit('changed', res.data.data)
          fetchData()
        })
        .catch(err => {
          errors.value = useExtractErrors(err)
        })
      loading.value = false
    }

    const handleFinancial = async () => {
      loading.value = true
      const request = finanicalData.value
        ? updateDistributorFinancialSettings(props.distributorId, {
            ...form.value,
          })
        : storeDistributorFinancialSettings(props.distributorId, {
            ...form.value,
          })
      await request
        .then(async res => {
          success.value = true
          emit('changed', res.data.data)
          fetchData()
        })
        .catch(err => {
          errors.value = useExtractErrors(err)
        })
      loading.value = false
    }

    const handleInvoice = async () => {
      loading.value = true
      const request = invoiceData.value
        ? updateDistributorInvoiceSettings(props.distributorId, {
            ...form.value,
          })
        : storeDistributorInvoiceSettings(props.distributorId, {
            ...form.value,
          })
      await request
        .then(async res => {
          success.value = true
          emit('changed', res.data.data)
          fetchData()
        })
        .catch(err => {
          errors.value = useExtractErrors(err)
        })
      loading.value = false
    }

    const handlePricing = async () => {
      loading.value = true
      const request = pricingData.value
        ? updateDistributorPricingSettings(props.distributorId, {
            ...form.value,
          })
        : storeDistributorPricingSettings(props.distributorId, {
            ...form.value,
          })
      await request
        .then(async res => {
          success.value = true
          emit('changed', res.data.data)
          fetchData()
        })
        .catch(err => {
          errors.value = useExtractErrors(err)
        })
      loading.value = false
    }

    const handleCoreOrder = async () => {
      loading.value = true
      form.value['core:order_report_email'] = form.value['core:order_report_email'].replace(' ', '').split(',')

      const request = coreOrderData.value
        ? updateDistributorCoreOrderSettings(props.distributorId, {
            ...form.value,
          })
        : storeDistributorCoreOrderSettings(props.distributorId, {
            ...form.value,
          })
      await request
        .then(async res => {
          success.value = true
          emit('changed', res.data.data)
          fetchData()
        })
        .catch(err => {
          errors.value = useExtractErrors(err)
        })
      loading.value = false
    }

    const handleRewardPoint = async () => {
      loading.value = true

      const request = rewardPointData.value
        ? updateDistributorRewardPointSettings(props.distributorId, {
            ...form.value,
          })
        : storeDistributorRewardPointSettings(props.distributorId, {
            ...form.value,
          })
      await request
        .then(async res => {
          success.value = true
          emit('changed', res.data.data)
          fetchData()
        })
        .catch(err => {
          errors.value = useExtractErrors(err)
        })
      loading.value = false
    }

    const submit = () => {
      errors.value = null
      success.value = null

      switch (type.value) {
        case 'blacklist':
          handleBlackList()
          break
        case 'whitelist':
          handleWhiteList()
          break
        case 'medical_card':
          handleMedicalCard()
          break
        case 'financial':
          handleFinancial()
          break
        case 'invoice':
          handleInvoice()
          break
        case 'pricing':
          handlePricing()
          break
        case 'core:order':
          handleCoreOrder()
          break
        case 'reward_point':
          handleRewardPoint()
          break
        default:
          errors.value = ['Error: Unknown type']
          break
      }
    }

    return {
      loading,
      success,
      errors,
      submit,
      form,
      t,
      icons: {
        mdiClose,
      },
      brandOptions,
      catOptions,
      noteOptions,
      boolOptions,
      generateStrategyOptions,
      blacklistData,
      whitelistData,
      medicalCardData,
      isPayAndClaimEnabled,
      convertString,
    }
  },
}
</script>
