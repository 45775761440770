<template>
  <v-form
    ref="formRef"
    @submit.prevent="onSubmit"
  >
    <v-card>
      <v-card-title class="text-sm-h5 d-flex justify-space-between">
        <slot name="title">
          Claim Plan
        </slot>

        <v-icon @click="onClose">
          {{ icons.mdiClose }}
        </v-icon>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col>
            <translation-locales />
          </v-col>

          <v-col
            v-if="!disabledFields.includes('distributor_id')"
            cols="6"
          >
            <v-autocomplete
              v-model="form.distributor_id"
              :items="distributorOptions"
              item-text="title"
              item-value="value"
              label="Distributor"
              outlined
              :rules="[validators.required]"
              :disabled="!!resource"
            ></v-autocomplete>
          </v-col>

          <v-col cols="6">
            <v-select
              v-model="form.fulfillment"
              :items="fulfillmentOptions"
              item-text="title"
              item-value="value"
              label="Fulfillment method when approved"
              outlined
              :disabled="disabledFields.includes('fulfillment')"
            />
          </v-col>

          <v-col cols="12">
            <translatable-input
              v-model="form.name"
              label="Name"
              :disabled="disabledFields.includes('name')"
            />
          </v-col>

          <v-col cols="12">
            <translatable-editor
              v-model="form.description"
              label="Description (Details)"
              outlined
              :disabled="disabledFields.includes('description')"
            />
          </v-col>

          <v-col cols="6">
            <p>Effective Date:</p>
            <v-date-picker v-model="form.effective_date" />
          </v-col>

          <v-col cols="6">
            <p>Expires at:</p>
            <v-date-picker v-model="form.expires_at" />
          </v-col>

          <v-col cols="6">
            <v-text-field
              v-model.number="form.reward_points"
              label="Reward Points (Lump Sum Gift)"
              type="number"
              outlined
            />
          </v-col>

          <v-col cols="12">
            <h3>Rule</h3>
            <p>Control the claims that a participant can make <b>for the entire plan</b>.</p>
            <p>For example, if you set max_quantity=10, the user claimed 9 different items in the past N days, he can make the last claim for any item under the plan.</p>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  v-model.number="form.rule.max_quantity"
                  label="Max Quantity (Quota)"
                  hint=" 0 = Unlimited. Each unique item in a claim is at least 1 quantity."
                  type="number"
                  outlined
                  :suffix="form.rule.max_quantity === 0 ? 'Unlimited' : ''"
                  :disabled="disabledFields.includes('rule.max_quantity')"
                />
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model.number="form.rule.max_total"
                  label="Max Claim Amount Total"
                  hint="0 = Unlimited."
                  type="number"
                  :suffix="form.rule.max_total === 0 ? 'Unlimited' : ''"
                  outlined
                  :disabled="disabledFields.includes('rule.max_total')"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  v-model.number="form.rule.min_item_per_claim"
                  label="Min Item per Claim"
                  hint="Normally you just want to leave it '1'"
                  type="number"
                  outlined
                  :disabled="disabledFields.includes('rule.min_item_per_claim')"
                />
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model.number="form.rule.max_item_per_claim"
                  label="Max Item per Claim"
                  hint="1 is single item claim, !==1 is multiple"
                  type="number"
                  outlined
                  :disabled="disabledFields.includes('rule.max_item_per_claim')"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <h3>Validations before submit</h3>
              </v-col>
              <v-col cols="4">
                <v-switch
                  v-model="form.rule.validate_quantity"
                  label="Plan&Item Quantity (Quota)"
                  class="mt-0"
                />
              </v-col>
              <v-col cols="4">
                <v-switch
                  v-model="form.rule.validate_total"
                  label="Plan&Item Total/Balance"
                  class="mt-0"
                />
              </v-col>
              <v-col cols="4">
                <v-switch
                  v-model="form.rule.validate_owner_participant"
                  label="participant is designated"
                  class="mt-0"
                />
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12">
            <h3>Manifest</h3>
            <p>Normally you just want to show either one.</p>

            <v-row>
              <v-col cols="6">
                <v-switch
                  v-model="form.manifest.show_max_quantity"
                  label="Show Max Quantity (Quota)"
                  outlined
                  :disabled="disabledFields.includes('manifest.show_max_quantity')"
                />
              </v-col>
              <v-col cols="6">
                <v-switch
                  v-model="form.manifest.show_max_total"
                  label="Show Max Claim Amount Total"
                  outlined
                  :disabled="disabledFields.includes('manifest.show_max_total')"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>

      <v-col cols="12">
        <v-switch
          v-model="form.active"
          label="Active"
          outlined
          :dsiabled="!resource"
        />
      </v-col>

      <v-card-actions>
        <v-spacer />
        <v-btn
          type="submit"
          color="success"
          :loading="loading"
          :disabled="loading"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { TranslatableEditor, TranslatableInput, TranslationLocales } from '@/components'
import { useDistributor, useTranslatable } from '@/composables'
import { t } from '@/plugins/i18n'
import { required } from '@core/utils/validation'
import { mdiClose } from '@mdi/js'
import { ref } from '@vue/composition-api'

import { now } from '@/@core/utils/filter'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export default {
  components: {
    TranslatableInput,
    TranslatableEditor,
    TranslationLocales,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    resource: {
      type: Object,
      default: null,
    },
    disabledFields: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const { distributorOptions } = useDistributor()
    const isEditing = ref(false)

    const useFieldValue = (fieldName, defaultValue) => (props.resource ? props.resource[fieldName] : defaultValue)

    // const defaultFormValue = ({
    //   name: useFieldValue('name', useTranslatable()),
    //   nicename: useFieldValue('nicename', 'Example Default Name'),
    //   active: useFieldValue('active', false),
    // })

    const defaultFormValue = {
      name: useFieldValue('name', useTranslatable()),
      description: useFieldValue('description', useTranslatable()),
      distributor_id: useFieldValue('distributor_id', null),
      fulfillment: 'plan',
      effective_date: useFieldValue('effective_date', now()),
      expires_at: useFieldValue('expires_at', now()),
      active: useFieldValue('active', false),
      reward_points: useFieldValue('reward_points', 0),
      rule: useFieldValue('rule', {
        max_quantity: 0,
        max_total: 0,
        validate_quantity: false,
        validate_total: false,
        validate_owner_participant: true,
        min_item_per_claim: 1,
        max_item_per_claim: 99,
      }),
      manifest: useFieldValue('manifest', {
        show_max_quantity: true,
        show_max_total: false,
      }),
    }

    if (defaultFormValue.effective_date) {
      defaultFormValue.effective_date = now(defaultFormValue.effective_date).format('YYYY-MM-DD')
    }
    if (defaultFormValue.expires_at) {
      defaultFormValue.expires_at = now(defaultFormValue.expires_at).format('YYYY-MM-DD')
    }

    const form = ref({
      ...defaultFormValue,
    })

    const formRef = ref(null)

    const onSubmit = () => {
      if (formRef.value.validate()) {
        console.log(form.value)

        emit('submit', form.value)
      }
    }

    const onClose = () => {
      emit('close')
    }

    const fulfillmentOptions = [
      { title: 'By Plan Quota/Budget - no coins required', value: 'plan' },
      { title: 'Credit - requires sufficient coins', value: 'credit' },
    ]

    return {
      distributorOptions,
      isEditing,
      fulfillmentOptions,

      form,
      formRef,

      // Rules
      validators: {
        required,
      },

      menu1: ref(false),
      menu2: ref(false),

      t,
      onSubmit,
      onClose,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>
