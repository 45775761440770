<template>
  <v-dialog
    v-model="isDialogVisible"
    max-width="800"
    persistent
    full-height
  >
    <!-- Dialog Activator -->
    <template #activator="{ on, attrs }">
      <v-btn
        outlined
        class="mb-1 me-3"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon
          size="17"
          class="me-1"
        >
          {{ icons.mdiHumanEdit }}
        </v-icon>
        <span>Edit</span>
      </v-btn>
    </template>

    <!-- Dialog Content -->
    <role-form
      ref="formRef"
      :resource="role"
      @close="onClose"
      @submit="onSubmit"
    >
      <template #title>
        {{ $t('Edit User Role') }}
      </template>
    </role-form>
  </v-dialog>
</template>

<script>
import { useNotifySuccess } from '@/composables'
import { mdiHumanEdit, mdiRefresh } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { roleService } from '../../services'
import RoleForm from './RoleForm.vue'

export default {
  components: {
    RoleForm,
  },
  props: {
    role: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const formRef = ref(null)
    const isDialogVisible = ref(false)

    const onClose = () => {
      isDialogVisible.value = false
    }

    const onSubmit = async data => {
      try {
        await roleService.updateRole(props.resource.id, data)

        isDialogVisible.value = false

        emit('updated')

        useNotifySuccess({
          content: 'User Role has been updated successfully.',
        })
      } catch (error) {
        console.log(error)
      } finally {
        loading.value = false
      }
    }

    return {
      formRef,
      isDialogVisible,

      onClose,
      onSubmit,

      icons: {
        mdiHumanEdit,
        mdiRefresh,
      },
    }
  },
}
</script>
