var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('filter-section', {
    attrs: {
      "cols": [3, 3]
    }
  }, [_c('search-filter', {
    attrs: {
      "slot": "col_0"
    },
    slot: "col_0",
    model: {
      value: _vm.filters,
      callback: function callback($$v) {
        _vm.filters = $$v;
      },
      expression: "filters"
    }
  }), _c('v-chip', {
    attrs: {
      "slot": "col_1",
      "color": _vm.filters.claim_plan_id_not ? 'success' : ''
    },
    on: {
      "click": function click($event) {
        _vm.filters.claim_plan_id_not = _vm.filters.claim_plan_id_not ? null : _vm.plan.id;
        _vm.paginate();
      }
    },
    slot: "col_1"
  }, [_vm._v(" Filter No Plan User ")])], 1), _c('user-plan-assignment-table', {
    ref: "tableRef",
    attrs: {
      "plan": _vm.plan,
      "table-list": _vm.tableList
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }