var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    attrs: {
      "col": "6"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "color": "primary"
    },
    on: {
      "input": _vm.updateTimestamp
    },
    model: {
      value: _vm.temp.date,
      callback: function callback($$v) {
        _vm.$set(_vm.temp, "date", $$v);
      },
      expression: "temp.date"
    }
  })], 1), _c('v-col', {
    attrs: {
      "col": "6"
    }
  }, [_c('v-time-picker', {
    attrs: {
      "color": "primary"
    },
    on: {
      "input": _vm.updateTimestamp
    },
    model: {
      value: _vm.temp.time,
      callback: function callback($$v) {
        _vm.$set(_vm.temp, "time", $$v);
      },
      expression: "temp.time"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }