import { ref } from '@vue/composition-api'
import { actionService, flowService } from '../services'

export default function useFlow() {
  const actions = ref([])
  const flow = ref(null)

  const loadFlow = id =>
    flowService.show(id).then(data => {
      flow.value = data

      return data
    })

  const listActions = (query = {}) =>
    actionService.index(query).then(({ records }) => {
      actions.value = records

      return records
    })

  return {
    actions,
    listActions,
    flow,
    loadFlow,
  }
}
