var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "site-list"
    }
  }, [_c('v-row', {
    staticClass: "my-0 d-flex justify-end"
  }, [_c('v-col', {
    staticStyle: {
      "margin-right": "auto"
    },
    attrs: {
      "cols": "12",
      "sm": "2",
      "align": "right"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center"
  }, [_c('v-select', {
    staticClass: "mr-2",
    staticStyle: {
      "min-width": "200px"
    },
    attrs: {
      "placeholder": "Category",
      "items": _vm.categoryOptions,
      "item-text": "title",
      "item-value": "value",
      "hide-details": "",
      "single-line": "",
      "outlined": "",
      "chips": "",
      "multiple": "",
      "clearable": ""
    },
    model: {
      value: _vm.filters.category_in,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "category_in", $$v);
      },
      expression: "filters.category_in"
    }
  }), _c('distributor-id-filter', {
    staticStyle: {
      "min-width": "200px"
    },
    attrs: {
      "chips": "",
      "clearable": ""
    },
    model: {
      value: _vm.filters,
      callback: function callback($$v) {
        _vm.filters = $$v;
      },
      expression: "filters"
    }
  }), _c('v-btn', {
    attrs: {
      "color": "primary",
      "block": "",
      "loading": _vm.loading,
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.loadData
    }
  }, [_c('v-icon', {
    staticClass: "prefix-icon"
  }, [_vm._v(" " + _vm._s(_vm.icons.mdiRefresh) + " ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('common.list.refresh')) + " ")])], 1)], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "2",
      "align": "right"
    }
  }, [_c('v-btn', {
    attrs: {
      "depressed": "",
      "block": "",
      "color": "success"
    },
    on: {
      "click": function click($event) {
        return _vm.setAside(null);
      }
    }
  }, [_c('v-icon', {
    staticClass: "prefix-icon"
  }, [_vm._v(" " + _vm._s(_vm.icons.mdiPlus) + " ")]), _vm._v(" " + _vm._s(_vm.$t('common.list.create')) + " ")], 1)], 1)], 1), _c('v-card', [_c('v-data-table', {
    staticClass: "text-no-wrap",
    attrs: {
      "headers": _vm.tableColumns,
      "items": _vm.tableItems,
      "options": _vm.options,
      "server-items-length": _vm.tableTotal,
      "loading": _vm.loading,
      "header-props": _vm.headerprops
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.options = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.id",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" #" + _vm._s(item.id) + " ")];
      }
    }, {
      key: "item.name",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('span', {
          staticClass: "text-no-wrap"
        }, [_vm._v(_vm._s(_vm.t(item.name)))])];
      }
    }, {
      key: "item.value",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "text-wrap dict-value"
        }, [_c('b', [_vm._v(_vm._s(_vm.t(item.value)))])]), item.alias ? _c('div', {
          staticClass: "text-wrap dict-value mt-2 ml-4"
        }, [_c('b', [_vm._v(" (alias) " + _vm._s(_vm.t(item.alias.value)))])]) : _vm._e(), _c('v-row', {
          staticClass: "py-2"
        }, _vm._l(item.children, function (child) {
          return _c('v-col', {
            key: child.id,
            staticClass: "text--secondary dict-value",
            attrs: {
              "cols": item.children.length > 10 ? 6 : 12
            }
          }, [_c('v-icon', {
            on: {
              "click": function click($event) {
                return _vm.setAside(child);
              }
            }
          }, [_vm._v(" " + _vm._s(_vm.icons.mdiPencilOutline) + " ")]), child.distributor ? _c('span', [_vm._v(" (" + _vm._s(child.distributor.slug) + ") ")]) : _vm._e(), _c('b', [_vm._v(" " + _vm._s(_vm.t(child.value)))])], 1);
        }), 1)];
      }
    }, {
      key: "item.distributor",
      fn: function fn(_ref4) {
        var _item$distributor;
        var item = _ref4.item;
        return [_c('span', {
          staticClass: "text-wrap"
        }, [_vm._v(_vm._s((_item$distributor = item.distributor) === null || _item$distributor === void 0 ? void 0 : _item$distributor.business_name))])];
      }
    }, {
      key: "item.notes",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('span', {
          staticClass: "text-wrap"
        }, [_vm._v(_vm._s(item.notes))])];
      }
    }, {
      key: "item.active",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('span', {
          staticClass: "text-no-wrap"
        }, [_vm._v(_vm._s(item.active ? 'Yes' : 'No'))])];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('div', {
          staticClass: "d-flex align-center justify-center"
        }, [_c('v-menu', {
          attrs: {
            "bottom": "",
            "left": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref8) {
              var on = _ref8.on,
                attrs = _ref8.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "small": "",
                  "icon": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "size": "18"
                }
              }, [_vm._v(" " + _vm._s(_vm.icons.mdiDotsVertical) + " ")])], 1)];
            }
          }], null, true)
        }, [_c('v-list', [_c('v-list-item', {
          attrs: {
            "link": ""
          },
          on: {
            "click": function click($event) {
              return _vm.setAside(item);
            }
          }
        }, [_c('v-list-item-title', [_c('v-icon', {
          staticClass: "me-2",
          attrs: {
            "size": "18"
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiPencilOutline) + " ")]), _c('span', [_vm._v("Edit")])], 1)], 1), !item.distributor_id ? _c('v-list-item', {
          attrs: {
            "link": ""
          },
          on: {
            "click": function click($event) {
              return _vm.setAside(item, 'store_child');
            }
          }
        }, [_c('v-list-item-title', [_c('v-icon', {
          staticClass: "me-2",
          attrs: {
            "size": "18"
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiPlus) + " ")]), _c('span', [_vm._v("Add Child")])], 1)], 1) : _vm._e(), _c('v-list-item', {
          attrs: {
            "link": ""
          },
          on: {
            "click": function click($event) {
              return _vm.deleteDictionary(item);
            }
          }
        }, [_c('v-list-item-title', [_c('v-icon', {
          staticClass: "me-2",
          attrs: {
            "size": "18"
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiDeleteOutline) + " ")]), _c('span', [_vm._v("Delete")])], 1)], 1)], 1)], 1)], 1)];
      }
    }], null, true)
  })], 1), _vm.aside === 'dictionary' ? _c('dictionary-aside', {
    attrs: {
      "resource": _vm.resource
    },
    on: {
      "changed": function changed($event) {
        _vm.loadData();
        _vm.aside = false;
      }
    },
    model: {
      value: _vm.aside,
      callback: function callback($$v) {
        _vm.aside = $$v;
      },
      expression: "aside"
    }
  }) : _vm._e(), _vm.aside === 'store_child' ? _c('store-dictionary-child-aside', {
    attrs: {
      "resource": _vm.resource
    },
    on: {
      "changed": function changed($event) {
        _vm.loadData();
        _vm.aside = false;
      }
    },
    model: {
      value: _vm.aside,
      callback: function callback($$v) {
        _vm.aside = $$v;
      },
      expression: "aside"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }