<template>
  <v-dialog
    v-model="isDialogVisible"
    max-width="800"
    persistent
    full-height
  >
    <template #activator="{ on, attrs }">
      <a
        v-bind="attrs"
        v-on="on"
      >
        <slot>
          <v-icon
            size="17"
            class="me-3"
          >
            {{ icons.mdiDatabaseEdit }}
          </v-icon>
          <span>Edit</span>
        </slot>
      </a>
    </template>

    <flow-form
      v-if="resource"
      ref="formRef"
      :resource="resource"
      :loading="loading"
      @close="onClose"
      @submit="onSubmit"
    >
      <template #title>
        {{ $t('Edit Flow') }}
      </template>
    </flow-form>
  </v-dialog>
</template>

<script>
import { useNotifyErrors, useNotifySuccess } from '@/composables'
import { mdiDatabaseEdit } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { flowService } from '../../services'
import FlowForm from './FlowForm.vue'

export default {
  components: {
    FlowForm,
  },
  props: {
    resource: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const formRef = ref(null)
    const loading = ref(false)
    const isDialogVisible = ref(false)

    const onClose = () => {
      isDialogVisible.value = false
    }

    const onSubmit = async data => {
      loading.value = true

      await flowService.update(props.resource.id, {
        ...data,
      }).then(() => {
        isDialogVisible.value = false
        emit('updated')
        useNotifySuccess({
          content: 'Flow has been updated successfully.',
        })
      }).catch(error => {
        useNotifyErrors(error)
      }).finally(() => {
        loading.value = false
      })
    }

    return {
      formRef,
      isDialogVisible,
      onClose,
      onSubmit,
      loading,
      icons: {
        mdiDatabaseEdit,
      },
    }
  },
}
</script>
