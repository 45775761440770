var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('create-dialog', {
    attrs: {
      "upload-path": "claim-plans/".concat(_vm.plan.id, "/photo")
    },
    on: {
      "created": function created($event) {
        return _vm.$emit('updated');
      }
    }
  }), _vm._l(_vm.plan.photos, function (photo) {
    return _c('div', {
      key: photo.id,
      staticClass: "mb-2 mt-2"
    }, [_c('span', {
      staticClass: "mr-2"
    }, [_vm._v("#" + _vm._s(photo.id))]), _c('img', {
      staticClass: "img img-thumbnail",
      attrs: {
        "src": photo.thumbnail,
        "alt": ""
      }
    }), _vm.$can('claim_plan_photo:delete') ? _c('v-icon', {
      attrs: {
        "size": "16"
      },
      on: {
        "click": function click($event) {
          _vm.confirmDeletePhoto(photo).then(function () {
            return _vm.$emit('updated');
          });
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.icons.mdiTrashCan) + " ")]) : _vm._e()], 1);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }