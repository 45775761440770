var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.flow ? _c('v-card', [_c('div', {
    staticClass: "pl-4 pt-4"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'flows'
      }
    }
  }, [_vm._v(" Back ")])], 1), _c('v-card-title', {
    staticClass: "d-flex justify-space-between"
  }, [_c('div', {
    staticClass: "d-flex align-center",
    staticStyle: {
      "min-width": "300px"
    }
  }, [_c('span', {
    staticClass: "mr-4"
  }, [_vm._v(" " + _vm._s(_vm.t(_vm.flow.name)) + " ")]), _c('v-chip', {
    staticClass: "mr-4",
    attrs: {
      "color": _vm.flow.active ? 'success' : 'gray'
    }
  }, [_vm._v(" " + _vm._s(_vm.flow.active ? 'Active' : 'Inactive') + " ")])], 1), _c('div', [_c('EditDialog', {
    attrs: {
      "resource": _vm.flow
    },
    on: {
      "updated": _vm.reloadFlow
    }
  }, [_c('v-btn', [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiPencil) + " ")]), _vm._v(" Edit Flow ")], 1)], 1)], 1)]), _c('v-card-text', [_c('v-tabs', [_c('v-tab', [_vm._v(" Actions ")]), _c('v-tab', [_vm._v(" User Process ")]), _c('v-tab-item', [_c('flow-action-tab', {
    attrs: {
      "flow": _vm.flow
    }
  })], 1), _c('v-tab-item', [_c('flow-process-tab', {
    attrs: {
      "flow": _vm.flow
    }
  })], 1)], 1)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }