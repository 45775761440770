var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-data-table', {
    staticClass: "table-section text-no-wrap",
    attrs: {
      "headers": _vm.columns,
      "items": _vm.items,
      "options": _vm.options,
      "server-items-length": _vm.itemsTotal,
      "loading": _vm.loading,
      "header-props": _vm.headerprops
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.options = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.user_id",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.user.email) + " ")];
      }
    }, {
      key: "item.expires_at",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('span', {
          staticClass: "text-no-wrap"
        }, [_vm._v(_vm._s(_vm.formatDate(item.expires_at)))])];
      }
    }, {
      key: "item.status",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('v-chip', {
          staticClass: "text-no-wrap",
          attrs: {
            "color": item.status === 'completed' ? 'success' : 'gray'
          }
        }, [_vm._v(" " + _vm._s(item.status) + " ")])];
      }
    }, {
      key: "item.processed_actions",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('span', {
          staticClass: "text-no-wrap"
        }, [_vm._v(_vm._s(item.processed_actions.length))])];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "d-flex align-center justify-center"
        }, [_c('v-menu', {
          attrs: {
            "bottom": "",
            "left": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref6) {
              var on = _ref6.on,
                attrs = _ref6.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "small": "",
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    return _vm.loadResource(item.id);
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "size": "18"
                }
              }, [_vm._v(" " + _vm._s(_vm.icons.mdiDotsVertical) + " ")])], 1)];
            }
          }], null, true)
        }, [_c('v-list', [_vm.resource ? [_vm.$can('user_process:delete', 'user_process_management') ? _c('delete-dialog', {
          attrs: {
            "resource": _vm.resource
          },
          on: {
            "updated": _vm.paginate
          }
        }) : _vm._e()] : _c('v-list-item', {
          staticStyle: {
            "min-width": "100px"
          }
        })], 2)], 1)], 1)];
      }
    }], null, true)
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }