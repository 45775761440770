import adminUserManagement from '@/modules/admin/menu/vertical'
import distributor from '@/modules/distributor/menu/vertical'
import reportManagement from '@/modules/reporting/menu/vertical'
import userManagement from '@/modules/user/menu/vertical'
import blog from './blog'
import claim from './claim'
import dashboard from './dashboard'
import distributorModule from './distributorModule'
import doctor from './doctor'
import flow from './flow'
import invoice from './invoice'
import medicalCard from './medicalCard'
import mobileApp from './mobileApp'
import news from './news'
import product from './product'
import rewardPoint from './rewardPoint'
import site from './site'
import utility from './utility'

export default [
  ...dashboard,
  ...distributor,

  ...site,
  ...claim,
  ...flow,
  ...rewardPoint,
  ...blog,
  ...news,
  ...distributorModule,
  ...doctor,
  ...invoice,
  ...medicalCard,
  ...product,
  ...mobileApp,
  ...userManagement,
  ...reportManagement,
  ...adminUserManagement,
  ...utility,
]
