<template>
  <v-card>
    <v-data-table
      :headers="columns"
      :items="items"
      :options.sync="options"
      :server-items-length="itemsTotal"
      :loading="loading"
      :header-props="headerprops"
      class="table-section text-no-wrap"
    >
      <!-- ID -->
      <template #[`item.id`]="{ item }">
        {{ item.id }}
      </template>

      <template #[`item.active`]="{ item }">
        <v-chip
          :color="item.active ? 'success' : 'error'"
          text-color="white"
        >
          {{ item.active ? 'Active' : 'Inactive' }}
        </v-chip>
      </template>

      <template #[`item.min_option`]="{ item }">
        <b>{{ item.min_option }}</b>
        <b v-show="item.max_option > item.min_option">({{ item.max_option }})</b>
      </template>

      <template #[`item.name`]="{ item }">
        <router-link
          class="font-weight-medium text-decoration-none"
          :to="{ name: 'claim-plan-selection-view', params: { id: item.id } }"
        >
          {{ t(item.name) }}
        </router-link>
      </template>

      <template #[`item.distributor_id`]="{item}">
        {{ item.distributor.nicename }}
      </template>

      <!-- actions -->
      <template #[`item.actions`]="{ item }">
        <div class="d-flex align-center justify-center">
          <!-- dropdown -->
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon size="18">
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-if="$can('claim_plan:view', 'claim_plan_management')"
                link
                :to="{ name: 'claim-plan-selection-view', params: { id: item.id } }"
              >
                <v-list-item-title>
                  <v-icon
                    size="18"
                    class="me-3"
                  >
                    {{ icons.mdiEyeOutline }}
                  </v-icon>
                  <span>View & Items</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="$can('claim_plan:delete', 'claim_plan_management')"
                @click="confirmDeleteSelection(item).then(paginate)"
              >
                <v-list-item-title>
                  <v-icon
                    size="18"
                    class="me-3"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { t } from '@/plugins/i18n'
import { formatDate } from '@core/utils/filter'
import {
  mdiDeleteOutline,
  mdiDotsVertical, mdiEyeOutline,
} from '@mdi/js'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import useClaimPlanSelection from '../../composables/useClaimPlanSelection'

dayjs.extend(utc)

export default {
  props: {
    tableList: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {
      loading, options, paginate, items, itemsTotal, headerprops,
    } = props.tableList

    const { confirmDeleteSelection } = useClaimPlanSelection()

    const columns = [
      { text: 'ID', value: 'id' },
      { text: 'ACTIVE', value: 'active' },
      { text: 'Name', value: 'name' },
      { text: 'Choices (Max)', value: 'min_option' },
      { text: 'Distributor', value: 'distributor_id' },
      {
        text: 'ACTIONS',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ]

    return {
      t,
      dayjs,
      formatDate,
      confirmDeleteSelection,

      // Table config
      loading,
      paginate,
      options,
      items,
      itemsTotal,
      headerprops,

      columns,

      icons: {
        mdiDotsVertical,
        mdiEyeOutline,
        mdiDeleteOutline,
      },
    }
  },
}
</script>
