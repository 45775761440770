<template>
  <div>
    <v-card v-if="flow">
      <div class="pl-4 pt-4">
        <router-link :to="{ name: 'flows' }">
          Back
        </router-link>
      </div>
      <v-card-title class="d-flex justify-space-between">
        <div
          class="d-flex align-center"
          style="min-width: 300px;"
        >
          <span class="mr-4">
            {{ t(flow.name) }}
          </span>

          <v-chip
            :color="flow.active ? 'success' : 'gray'"
            class="mr-4"
          >
            {{ flow.active ? 'Active' : 'Inactive' }}
          </v-chip>
        </div>

        <div>
          <EditDialog
            :resource="flow"
            @updated="reloadFlow"
          >
            <v-btn>
              <v-icon>
                {{ icons.mdiPencil }}
              </v-icon>
              Edit Flow
            </v-btn>
          </EditDialog>
        </div>
      </v-card-title>

      <v-card-text>
        <v-tabs>
          <v-tab>
            Actions
          </v-tab>
          <v-tab>
            User Process
          </v-tab>
          <v-tab-item>
            <flow-action-tab
              :flow="flow"
            />
          </v-tab-item>
          <v-tab-item>
            <flow-process-tab
              :flow="flow"
            />
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import EditDialog from '@/modules/flow/components/flow/EditDialog.vue'
import useFlow from '@/modules/flow/composables/useFlow'
import { t } from '@/plugins/i18n'
import router from '@/router'
import { mdiEye, mdiEyeOff, mdiPencil } from '@mdi/js'
import { onMounted } from '@vue/composition-api'
import FlowActionTab from './FlowActionTab.vue'
import FlowProcessTab from './FlowProcessTab.vue'

export default {
  components: {
    EditDialog,
    FlowProcessTab,
    FlowActionTab,
  },
  setup() {
    const { loadFlow, flow } = useFlow()

    const reloadFlow = () => loadFlow(router.currentRoute.params.id)

    onMounted(async () => {
      await reloadFlow()
    })

    return {
      flow,
      t,

      reloadFlow,

      icons: {
        mdiEye,
        mdiEyeOff,
        mdiPencil,
      },
    }
  },
}

</script>
