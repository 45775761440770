var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "formRef",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSubmit.apply(null, arguments);
      }
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "text-sm-h5 d-flex justify-space-between"
  }, [_vm._t("title", function () {
    return [_vm._v(" Resource ")];
  }), _c('v-icon', {
    on: {
      "click": _vm.onClose
    }
  }, [_vm._v(" " + _vm._s(_vm.icons.mdiClose) + " ")])], 2), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('translation-locales')], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h3', {
    staticClass: "mb-4"
  }, [_vm._v(" Name ")]), _c('translatable-input', {
    attrs: {
      "label": "Item Name"
    },
    model: {
      value: _vm.form.dictionary.value,
      callback: function callback($$v) {
        _vm.$set(_vm.form.dictionary, "value", $$v);
      },
      expression: "form.dictionary.value"
    }
  }), _vm._l(_vm.form.notes, function (note, index) {
    return _c('div', {
      key: note.type + index,
      staticClass: "ml-8 d-flex"
    }, [_c('v-select', {
      staticClass: "mr-4",
      staticStyle: {
        "max-width": "250px"
      },
      attrs: {
        "items": _vm.noteTypeOptions,
        "item-value": "value",
        "item-text": "title"
      },
      model: {
        value: _vm.form.notes[index].type,
        callback: function callback($$v) {
          _vm.$set(_vm.form.notes[index], "type", $$v);
        },
        expression: "form.notes[index].type"
      }
    }), _c('translatable-input', {
      staticClass: "mr-4",
      attrs: {
        "label": "Item Name"
      },
      model: {
        value: _vm.form.notes[index].content,
        callback: function callback($$v) {
          _vm.$set(_vm.form.notes[index], "content", $$v);
        },
        expression: "form.notes[index].content"
      }
    }), _c('a', {
      staticClass: "mt-4",
      on: {
        "click": function click($event) {
          return _vm.destroyNoteType(_vm.form.notes[index]);
        }
      }
    }, [_vm._v("Remove")])], 1);
  }), _vm.nextNoteType ? _c('a', {
    on: {
      "click": _vm.addNote
    }
  }, [_vm._v(" ADD NOTE + ")]) : _vm._e()], 2), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h3', [_vm._v("Budget Target & Period")])]), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    staticClass: "mr-2",
    attrs: {
      "type": "number",
      "suffix": _vm.form.max_quantity === 0 ? 'Unlimited' : '',
      "label": "Max Quantity given a time period. 0=unlimited. "
    },
    model: {
      value: _vm.form.max_quantity,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "max_quantity", $$v);
      },
      expression: "form.max_quantity"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "suffix": _vm.form.max_total_per_quantity === 0 ? 'Unlimited' : '',
      "label": "Each Quantity cannot claim more than this total given a time period. 0=unlimited. "
    },
    model: {
      value: _vm.form.max_total_per_quantity,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "max_total_per_quantity", $$v);
      },
      expression: "form.max_total_per_quantity"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "step": 0.05,
      "label": "Max Reimbursement Rate. 0.0 ~ 1.0"
    },
    model: {
      value: _vm.form.max_reimbursement_rate,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "max_reimbursement_rate", $$v);
      },
      expression: "form.max_reimbursement_rate"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v("Budget is calculated per:")])]), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number"
    },
    model: {
      value: _vm.form.interval_count,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "interval_count", $$v);
      },
      expression: "form.interval_count"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.intervalUnitOptions,
      "item-value": "value",
      "item-text": "title"
    },
    model: {
      value: _vm.form.interval_unit,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "interval_unit", $$v);
      },
      expression: "form.interval_unit"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Grant budget to:",
      "items": _vm.grantedToOptions,
      "item-text": "title",
      "item-value": "value"
    },
    model: {
      value: _vm.form.granted_to,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "granted_to", $$v);
      },
      expression: "form.granted_to"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h3', {
    staticClass: "mb-4"
  }, [_vm._v(" Rule ")]), _c('v-switch', {
    staticClass: "mr-8",
    attrs: {
      "label": "(Optional) Require Alias. e.g. point to GP Diagnosis",
      "outlined": ""
    },
    model: {
      value: _vm.form.require_alias,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "require_alias", $$v);
      },
      expression: "form.require_alias"
    }
  }), _vm.form.require_alias ? [_c('v-autocomplete', {
    staticClass: "mt-4 mb-4",
    attrs: {
      "items": _vm.dictionaryOptions,
      "item-text": "title",
      "item-value": "value",
      "label": "Alias e.g. alias to GP, submit an extra child from GP.",
      "outlined": "",
      "hide-details": "",
      "clearable": ""
    },
    model: {
      value: _vm.form.dictionary.alias_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form.dictionary, "alias_id", $$v);
      },
      expression: "form.dictionary.alias_id"
    }
  })] : _vm._e()], 2), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h3', {
    staticClass: "mb-4"
  }, [_vm._v(" Manifest & Display ")]), _c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "Sort (ASC)"
    },
    model: {
      value: _vm.form.sort,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "sort", $$v);
      },
      expression: "form.sort"
    }
  }), _c('div', {
    staticClass: "d-flex"
  }, [_c('v-switch', {
    staticClass: "mr-8",
    attrs: {
      "label": "Active",
      "outlined": ""
    },
    model: {
      value: _vm.form.active,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "active", $$v);
      },
      expression: "form.active"
    }
  }), _c('v-switch', {
    staticClass: "mr-8",
    attrs: {
      "label": "Show Remaining Balance",
      "outlined": ""
    },
    model: {
      value: _vm.form.manifest.show_balance,
      callback: function callback($$v) {
        _vm.$set(_vm.form.manifest, "show_balance", $$v);
      },
      expression: "form.manifest.show_balance"
    }
  })], 1)], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "type": "submit",
      "color": "success",
      "loading": _vm.loading,
      "disabled": _vm.loading
    }
  }, [_vm._v(" Confirm ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }