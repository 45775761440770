<template>
  <v-dialog
    v-model="isDialogVisible"
    max-width="800"
    persistent
    full-height
  >
    <!-- Dialog Activator -->
    <template #activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>
          {{ icons.mdiPlusCircle }}
        </v-icon>
        <span>Add an item</span>
      </v-btn>
    </template>

    <!-- Dialog Content -->
    <claim-plan-item-form
      ref="formRef"
      :loading="loading"
      :disabled-fields="disabledFields"
      @close="onClose"
      @submit="onSubmit"
    >
      <template #title>
        {{ $t('Edit Claim Plan Item') }}
      </template>
    </claim-plan-item-form>
  </v-dialog>
</template>

<script>
import { useNotifyErrors, useNotifySuccess } from '@/composables'
import { mdiPlusCircle, mdiRefresh } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { claimPlanItemService } from '../../services'
import ClaimPlanItemForm from './ClaimPlanItemForm.vue'

export default {
  components: {
    ClaimPlanItemForm,
  },
  props: {
    claimPlan: {
      type: Object,
      default: null,
    },
    disabledFields: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const formRef = ref(null)
    const loading = ref(false)
    const isDialogVisible = ref(false)

    const onClose = () => {
      isDialogVisible.value = false
    }

    const onSubmit = async data => {
      try {
        loading.value = true

        await claimPlanItemService.store({
          ...data,
          claim_plan_id: props.claimPlan.id,
        })

        isDialogVisible.value = false

        emit('created')

        useNotifySuccess({
          content: 'Claim Plan Item has been created successfully.',
        })
      } catch (error) {
        useNotifyErrors(error)
      } finally {
        loading.value = false
      }
    }

    return {
      formRef,
      isDialogVisible,

      onClose,
      onSubmit,
      loading,

      icons: {
        mdiPlusCircle,
        mdiRefresh,
      },
    }
  },
}
</script>
