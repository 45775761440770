import axios from '@axiosV2'

const index = params => axios.get('/admin/claim-plan-items', { params })
const store = data => axios.post('/admin/claim-plan-items', data)
const update = (id, data) => axios.put(`/admin/claim-plan-items/${id}`, data)
const destroy = id => axios.delete(`/admin/claim-plan-items/${id}`)

const destroyNoteType = (id, type) => axios.delete(`/admin/claim-plan-items/${id}/notes/${type}`)

export default {
  index,
  store,
  update,
  destroy,
  destroyNoteType,
}
