var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h3', {
    staticClass: "mt-4 mb-2 ml-4 mr-4"
  }, [_vm._v(" You can add actions for user to finish ")]), _c('create-dialog', {
    attrs: {
      "flow": _vm.flow
    },
    on: {
      "created": _vm.paginate
    }
  }), _c('action-table', {
    attrs: {
      "table-list": _vm.tableList
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }