var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "800",
      "persistent": "",
      "full-height": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('a', _vm._g(_vm._b({
          staticClass: "mb-1 me-3",
          attrs: {
            "outlined": ""
          }
        }, 'a', attrs, false), on), [_c('v-icon', {
          staticClass: "me-1",
          attrs: {
            "size": "14"
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiPlus) + " ")]), _c('v-icon', {
          staticClass: "me-1",
          attrs: {
            "size": "14"
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiAccount) + " ")])], 1)];
      }
    }]),
    model: {
      value: _vm.isDialogVisible,
      callback: function callback($$v) {
        _vm.isDialogVisible = $$v;
      },
      expression: "isDialogVisible"
    }
  }, [_c('participant-form', {
    ref: "formRef",
    attrs: {
      "loading": _vm.loading,
      "disabled-fields": _vm.disabledFields,
      "user": _vm.user
    },
    on: {
      "close": _vm.onClose,
      "submit": _vm.onSubmit
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }