<template>
  <v-dialog
    v-model="isDialogVisible"
    max-width="800"
    persistent
    full-height
  >
    <template #activator="{ on, attrs }">
      <v-btn
        color="success"
        outlined
        v-bind="attrs"
        v-on="on"
      >
        <v-icon
          size="17"
          class="me-1"
        >
          {{ icons.mdiPlus }}
        </v-icon>
        <span>Create Action</span>
      </v-btn>
    </template>

    <action-form
      :loading="loading"
      :disabled-fields="disabledFields"
      @close="onClose"
      @submit="onSubmit"
    >
      <template #form-plugin>
        <v-col cols="12">
          <v-autocomplete
            v-model="form.actionable_id"
            :items="selectionOptions"
            item-text="title"
            item-value="value"
            label="Select a Claim Plan"
            outlined
          />
        </v-col>
      </template>
    </action-form>
  </v-dialog>
</template>

<script>
import { useNotifyErrors, useNotifySuccess } from '@/composables'
import useClaimPlanSelection from '@/modules/claim/composables/useClaimPlanSelection'
import { mdiPlus } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { actionService } from '../../services'
import ActionForm from './ActionForm.vue'

export default {
  components: {
    ActionForm,
  },
  props: {
    disabledFields: {
      type: Array,
      default: () => [],
    },
    flow: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: 'claim-plan-selections',
    },
  },
  setup(props, { emit }) {
    const isDialogVisible = ref(false)
    const loading = ref(false)

    const onClose = () => {
      isDialogVisible.value = false
    }

    const form = ref({
      actionable_id: null,
    })

    const { selectionOptions, loadClaimPlanSelections } = useClaimPlanSelection()
    loadClaimPlanSelections({ take: 999, distributor_id: props.flow.distributor_id })

    const onSubmit = async data => {
      try {
        loading.value = true
        await actionService.store(props.type, {
          ...data,
          ...form.value,
          flow_id: props.flow.id,
        })
        isDialogVisible.value = false
        emit('created')
        useNotifySuccess({
          content: 'action has been created successfully.',
        })
      } catch (error) {
        useNotifyErrors(error)
      } finally {
        loading.value = false
      }
    }

    return {
      form,
      selectionOptions,

      isDialogVisible,
      loading,
      onClose,
      onSubmit,
      icons: {
        mdiPlus,
      },
    }
  },
}
</script>
