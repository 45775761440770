var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-data-table', {
    staticClass: "table-section text-no-wrap",
    attrs: {
      "headers": _vm.columns,
      "items": _vm.items,
      "options": _vm.options,
      "server-items-length": _vm.itemsTotal,
      "loading": _vm.loading,
      "header-props": _vm.headerprops
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.options = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(_vm.t(item.dictionary.value)) + " "), item.dictionary.alias ? _c('small', [_vm._v(" | alias >> " + _vm._s(_vm.t(item.dictionary.alias.value)) + " ")]) : _vm._e(), _vm._l(item.notes, function (note) {
          return _c('v-chip', {
            key: note.id,
            staticClass: "mr-2",
            attrs: {
              "small": ""
            }
          }, [_vm._v(" " + _vm._s(_vm.t(note.content)) + " ")]);
        }), !item.active ? _c('v-chip', {
          attrs: {
            "color": "error",
            "text-color": "white"
          }
        }, [_vm._v(" Inactive ")]) : _vm._e()];
      }
    }, {
      key: "item.claim_plan_id",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(_vm.t(item.claim_plan.name)) + " ")];
      }
    }, {
      key: "item.max_quantity",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.max_quantity) + " * " + _vm._s(item.max_total_per_quantity) + " per " + _vm._s(item.interval_count) + " " + _vm._s(item.interval_unit) + " (" + _vm._s(item.max_reimbursement_rate * 100) + "%) ")];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "d-flex align-center justify-center"
        }, [_c('v-menu', {
          attrs: {
            "bottom": "",
            "left": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref5) {
              var on = _ref5.on,
                attrs = _ref5.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "small": "",
                  "icon": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "size": "18"
                }
              }, [_vm._v(" " + _vm._s(_vm.icons.mdiDotsVertical) + " ")])], 1)];
            }
          }], null, true)
        }, [_c('v-list', [_vm.$can('claim_plan:update', 'claim_plan_management') ? _c('edit-dialog', {
          attrs: {
            "resource": item
          },
          on: {
            "updated": _vm.paginate
          }
        }) : _vm._e(), _vm.$can('claim_plan:update', 'claim_plan_management') && item.active ? _c('v-list-item', {
          attrs: {
            "link": ""
          },
          on: {
            "click": function click($event) {
              _vm.inactivateItem(item).then(_vm.paginate);
            }
          }
        }, [_c('v-list-item-title', [_c('v-icon', {
          staticClass: "me-3",
          attrs: {
            "size": "18"
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiPauseCircleOutline) + " ")]), _c('span', [_vm._v("Inactivate")])], 1)], 1) : _vm._e(), _vm.$can('claim_plan:update', 'claim_plan_management') && !item.active ? _c('v-list-item', {
          attrs: {
            "link": ""
          },
          on: {
            "click": function click($event) {
              _vm.activateItem(item).then(_vm.paginate);
            }
          }
        }, [_c('v-list-item-title', [_c('v-icon', {
          staticClass: "me-3",
          attrs: {
            "size": "18"
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiPlayCircleOutline) + " ")]), _c('span', [_vm._v("Activate")])], 1)], 1) : _vm._e(), _vm.$can('claim_plan:delete', 'claim_plan_management') ? _c('v-list-item', {
          attrs: {
            "link": ""
          },
          on: {
            "click": function click($event) {
              _vm.confirmDeleteItem(item).then(_vm.paginate);
            }
          }
        }, [_c('v-list-item-title', [_c('v-icon', {
          staticClass: "me-3",
          attrs: {
            "size": "18"
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiDeleteOutline) + " ")]), _c('span', [_vm._v("Delete")])], 1)], 1) : _vm._e()], 1)], 1)], 1)];
      }
    }], null, true)
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }