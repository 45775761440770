<template>
  <v-dialog
    v-model="isDialogVisible"
    max-width="800"
    persistent
    full-height
  >
    <template #activator="{ on, attrs }">
      <a
        outlined
        class="mb-1 me-3"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon
          size="14"
          class="me-1"
        >
          {{ icons.mdiPlus }}
        </v-icon>
        <v-icon
          size="14"
          class="me-1"
        >
          {{ icons.mdiAccount }}
        </v-icon>
      </a>
    </template>

    <participant-form
      ref="formRef"
      :loading="loading"
      :disabled-fields="disabledFields"
      :user="user"
      @close="onClose"
      @submit="onSubmit"
    />
  </v-dialog>
</template>

<script>
import { useNotifyErrors, useNotifySuccess } from '@/composables'
import { mdiAccount, mdiPlus } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { participantService } from '../../services'
import ParticipantForm from './ParticipantForm.vue'

export default {
  components: {
    ParticipantForm,
  },
  props: {
    disabledFields: {
      type: Array,
      default: () => [],
    },
    notes: { // optional notes to add to this participant
      type: Array,
      default: () => [],
    },
    user: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const formRef = ref(null)
    const isDialogVisible = ref(false)
    const loading = ref(false)

    const onClose = () => {
      isDialogVisible.value = false
    }

    const onSubmit = async data => {
      try {
        loading.value = true

        await participantService.store({
          ...data,
          channel_user_id: props.user.uuid,
          notes: props.notes || [],
        })

        isDialogVisible.value = false

        emit('created')

        useNotifySuccess({
          content: 'participant has been created successfully.',
        })
      } catch (error) {
        useNotifyErrors(error)
      } finally {
        loading.value = false
      }
    }

    return {
      formRef,
      isDialogVisible,
      loading,
      onClose,
      onSubmit,
      icons: {
        mdiPlus,
        mdiAccount,
      },
    }
  },
}
</script>
