var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "formRef",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSubmit.apply(null, arguments);
      }
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "text-sm-h5 d-flex justify-space-between"
  }, [_vm._t("title", function () {
    return [_vm._v(" Flow ")];
  }), _c('v-icon', {
    on: {
      "click": _vm.onClose
    }
  }, [_vm._v(" " + _vm._s(_vm.icons.mdiClose) + " ")])], 2), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('search-user', {
    attrs: {
      "query": {
        distributor_id: _vm.flow.distributor_id
      },
      "outlined": ""
    },
    on: {
      "options": function options(_options) {
        return _vm.userOptions = _options;
      }
    }
  }), _c('v-select', {
    attrs: {
      "items": _vm.userOptions,
      "item-text": "title",
      "item-value": "value",
      "label": "User",
      "outlined": "",
      "disabled": _vm.disabledFields.includes('user_id') || _vm.resource
    },
    model: {
      value: _vm.form.user_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "user_id", $$v);
      },
      expression: "form.user_id"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.statusOptions,
      "item-text": "title",
      "item-value": "value",
      "label": "Status",
      "outlined": "",
      "disabled": _vm.disabledFields.includes('status') || _vm.resource
    },
    model: {
      value: _vm.form.status,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "status", $$v);
      },
      expression: "form.status"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" Expires At: "), _c('date-time-picker', {
    model: {
      value: _vm.form.expires_at,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "expires_at", $$v);
      },
      expression: "form.expires_at"
    }
  })], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "type": "submit",
      "color": "success",
      "loading": _vm.loading,
      "disabled": _vm.loading
    }
  }, [_vm._v(" Confirm ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }