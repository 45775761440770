export default () => [
  {
    title: 'Self',
    value: 'self',
  },
  {
    title: 'Spouse',
    value: 'spouse',
  },
  {
    title: 'Parent',
    value: 'parent',
  },
  {
    title: 'Parent In Law',
    value: 'parent-in-law',
  },
  {
    title: 'Child',
    value: 'child',
  },
  {
    title: 'Sibling',
    value: 'sibling',
  },
]
