<template>
  <v-data-table
    :headers="columns"
    :items="roles"
    :options.sync="options"
    :server-items-length="rolesTotal"
    :loading="loading"
    class="table-section text-no-wrap"
    :header-props="headerprops"
  >
    <!-- ID -->
    <template #[`item.id`]="{ item }">
      #{{ item.id }}
    </template>

    <!-- key -->
    <template #[`item.name`]="{ item }">
      {{ item.name }}
    </template>

    <!-- name -->
    <template #[`item.name_translation`]="{ item }">
      {{ t(item.name_translation) }}
    </template>

    <!-- actions -->
    <template #[`item.actions`]="{ item }">
      <div class="d-flex align-center justify-center">
        <v-menu
          bottom
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon size="18">
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              link
            >
              <v-list-item-title>
                <edit-dialog
                  :role="item"
                  @updated="onUserUpdated"
                />
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { useOldDataTable } from '@/composables'
import { t } from '@/plugins/i18n'
import { formatDate } from '@core/utils/filter'
import { mdiDotsVertical, mdiPencilOutline, mdiSync } from '@mdi/js'
import EditDialog from './EditDialog.vue'

export default {
  components: { EditDialog },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    roles: {
      type: Array,
      default: () => [],
    },
    rolesTotal: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const {
      options, headerprops, resetPage, optionWatcher,
    } = useOldDataTable()

    const columns = [
      { text: 'ID', value: 'id' },
      { text: 'Key', value: 'name' },
      { text: 'Name', value: 'name_translation' },
      {
        text: 'ACTIONS',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ]

    const onUserUpdated = () => {
      emit('updated')
    }

    optionWatcher(emit)

    return {
      t,

      options,

      columns,
      headerprops,

      formatDate,
      resetPage,

      onUserUpdated,

      icons: {
        mdiSync,
        mdiDotsVertical,
        mdiPencilOutline,
      },
    }
  },
}
</script>
