import { mdiClipboardListOutline, mdiFileDocumentOutline, mdiFormatListChecks } from '@mdi/js'

export default [
  {
    subheader: 'Claim Management',
  },
  {
    title: 'Claim Plans',
    icon: mdiFileDocumentOutline,
    to: 'claim-plans',
  },
  {
    title: 'Plan Items',
    icon: mdiClipboardListOutline,
    to: 'claim-plan-items',
  },
  {
    title: 'Plan Selections',
    icon: mdiFormatListChecks,
    to: 'claim-plan-selections',
  },
]
