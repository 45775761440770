<template>
  <v-form
    ref="formRef"
    @submit.prevent="onSubmit"
  >
    <v-card>
      <v-card-title class="text-sm-h5 d-flex justify-space-between">
        <slot name="title">
          Participant
        </slot>

        <v-icon @click="onClose">
          {{ icons.mdiClose }}
        </v-icon>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="form.first_name"
              label="*First Name"
              :rules="[validators.required]"
              outlined
              dense
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="form.last_name"
              label="*Last Name"
              :rules="[validators.required]"
              outlined
              dense
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="form.email"
              label="*Email"
              :rules="[validators.required]"
              outlined
              dense
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="form.phone"
              label="*Phone"
              :rules="[validators.required]"
              outlined
              dense
            />
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="form.gender"
              :items="[{ value: 'male', text: 'Male' }, { value: 'female', text: 'Female' }]"
              label="Gender"
              outlined
              dense
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="form.id_no"
              label="ID No."
              outlined
              dense
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="form.employee_id"
              label="Employee ID"
              outlined
              dense
            />
          </v-col>
          <v-col cols="6">
            <v-menu
              v-model="birthdayMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.birthday"
                  label="Birthday"
                  outlined
                  dense
                  readonly
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="form.birthday"
                no-title
                @input="birthdayMenu = false"
              />
            </v-menu>
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="form.relationship"
              label="Relationship"
              outlined
              :items="relationshipOptions"
              item-text="title"
              item-value="value"
            />
          </v-col>
          <v-col cols="6">
            <v-switch
              v-model="form.reusable"
              label="Default Always Reusable"
              outlined
              disabled
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          type="submit"
          color="success"
          :loading="loading"
          :disabled="loading"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { required } from '@/@core/utils/validation';
import { useRelationships } from '@/composables';
import { mdiClose } from '@mdi/js';
import { ref } from '@vue/composition-api';

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default: () => [],
    },
    defaultValue: {
      type: Object,
      default: () => {},
    },
    user: {
      type: Object,
      default: null,
    },
    resource: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const isEditing = ref(false)

    const useFieldValue = (fieldName, defaultValue) => (props.resource ? props.resource[fieldName] : (props.defaultValue?.[fieldName] || defaultValue))

    const defaultFormValue = ({
      reusable: useFieldValue('reusable', true),
      first_name: useFieldValue('first_name', props.user.profile?.first_name || ''),
      last_name: useFieldValue('last_name', props.user.profile?.last_name || ''),
      email: useFieldValue('email', props.user.email || ''),
      phone: useFieldValue('phone', props.user.phone || ''),
      gender: useFieldValue('gender', 'male'),
      id_no: useFieldValue('id_no', ''),
      employee_id: useFieldValue('employee_id', ''),
      birthday: useFieldValue('birthday', null),
      relationship: useFieldValue('relationship', 'self'),
    })

    const form = ref({
      ...defaultFormValue,
    })

    const formRef = ref(null)

    const birthdayMenu = ref(false)

    const onSubmit = () => {
      if (formRef.value.validate()) {
        emit('submit', form.value)
      }
    }

    const onClose = () => {
      // if (isEditing.value) {
      //   formRef.value.resetValidation()
      // } else {
      //   formRef.value.reset()
      //   Object.assign(form.value, defaultFormValue)
      // }
      emit('close')
    }

    const relationshipOptions = useRelationships()

    return {
      isEditing,
      form,
      formRef,
      validators: {
        required,
      },
      onSubmit,
      onClose,
      icons: {
        mdiClose,
      },
      birthdayMenu,
      relationshipOptions,
    }
  },
}
</script>
