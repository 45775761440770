import { useConfirm } from '@/composables'
import { documentService } from '../services'

export default () => {
  const confirmDeleteDocument = async document => {
    await useConfirm({
      title: 'Confirm Delete Document',
      content: 'Deleted items will not be visible.',
    })

    return documentService.destroy(document.id)
  }

  return {
    confirmDeleteDocument,
  }
}
