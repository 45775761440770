import { useConfirm } from '@/composables'
import { t } from '@/plugins/i18n'
import { computed, ref } from '@vue/composition-api'
import { claimPlanSelectionOptionService, claimPlanSelectionService } from '../services'

export default () => {
  const selection = ref(null)
  const selections = ref([])

  const selectionOptions = computed(() =>
    selections.value.map(selection => ({
      value: selection.id,
      title: t(selection.name),
    })),
  )

  const loadClaimPlanSelections = query =>
    claimPlanSelectionService.index(query).then(({ records }) => {
      selections.value = records

      return records
    })

  const loadClaimPlanSelection = id =>
    claimPlanSelectionService.show(id).then(data => {
      selection.value = data

      return data
    })

  const confirmDeleteSelection = async item => {
    await useConfirm({
      title: 'Confirm Delete Plan Selection',
      content: 'Users wont be able to see this selection again.',
    })

    return claimPlanSelectionService.destroy(item.id)
  }

  const confirmDeleteOption = async item => {
    await useConfirm({
      title: 'Confirm Delete Option',
      content: 'Users wont be able to see this option again.',
    })

    return claimPlanSelectionOptionService.destroy(item.id)
  }

  return {
    selection,
    selections,
    selectionOptions,
    loadClaimPlanSelection,
    loadClaimPlanSelections,
    confirmDeleteSelection,
    confirmDeleteOption,
  }
}
