var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "900",
      "persistent": "",
      "full-height": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('a', _vm._g(_vm._b({}, 'a', attrs, false), on), [_vm._t("default", function () {
          return [_c('v-icon', {
            staticClass: "me-2",
            attrs: {
              "size": "18"
            }
          }, [_vm._v(" " + _vm._s(_vm.icons.mdiAssistant) + " ")]), _c('span', [_vm._v("Create Plan Option")])];
        })], 2)];
      }
    }], null, true),
    model: {
      value: _vm.isDialogVisible,
      callback: function callback($$v) {
        _vm.isDialogVisible = $$v;
      },
      expression: "isDialogVisible"
    }
  }, [_vm.ready ? _c('claim-plan-selection-option-form', {
    ref: "formRef",
    attrs: {
      "loading": _vm.loading,
      "claim-plan-options": _vm.planOptions
    },
    on: {
      "close": _vm.onClose,
      "submit": _vm.onSubmit
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }