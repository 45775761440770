var render = function () {
  var _vm$distributorBalanc;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "user-view"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5",
      "lg": "4"
    }
  }, [_vm.distributor ? _c('distributor-profile-panel', {
    attrs: {
      "distributor": _vm.distributor
    },
    on: {
      "openEditPhotoAside": _vm.setAside,
      "updated": _vm.reload
    }
  }) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7",
      "lg": "8"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.distributor ? _c('distributor-statistics', {
    attrs: {
      "health-coin-count": Number((_vm$distributorBalanc = _vm.distributorBalance.total) !== null && _vm$distributorBalanc !== void 0 ? _vm$distributorBalanc : 0)
    }
  }) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-tabs', {
    staticClass: "user-tabs",
    attrs: {
      "show-arrows": ""
    },
    model: {
      value: _vm.distributorTab,
      callback: function callback($$v) {
        _vm.distributorTab = $$v;
      },
      expression: "distributorTab"
    }
  }, _vm._l(_vm.tabs, function (tab) {
    return _c('v-tab', {
      key: tab.title
    }, [_c('v-icon', {
      staticClass: "me-3",
      attrs: {
        "size": "20"
      }
    }, [_vm._v(" " + _vm._s(tab.icon) + " ")]), _c('span', [_vm._v(_vm._s(tab.title))])], 1);
  }), 1), _c('v-tabs-items', {
    staticClass: "mt-5 pa-1",
    attrs: {
      "id": "user-tabs-content"
    },
    model: {
      value: _vm.distributorTab,
      callback: function callback($$v) {
        _vm.distributorTab = $$v;
      },
      expression: "distributorTab"
    }
  }, [_c('v-tab-item', [_vm.$can('distributor_team:list', 'distributor_team_management') ? _c('distributor-team-list', {
    attrs: {
      "distributor-id": _vm.distributorId
    }
  }) : _vm._e()], 1), _c('v-tab-item', [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.openDialog
    }
  }, [_vm._v(" Edit Settings ")]), _c('v-dialog', {
    attrs: {
      "max-width": "800px"
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "text-h5"
  }, [_vm._v("Distributor Settings")])]), _c('v-tabs', {
    attrs: {
      "background-color": "transparent"
    },
    model: {
      value: _vm.dialogTab,
      callback: function callback($$v) {
        _vm.dialogTab = $$v;
      },
      expression: "dialogTab"
    }
  }, [_c('v-tab', [_vm._v("Blacklist")]), _c('v-tab', [_vm._v("Whitelist")]), _c('v-tab', [_vm._v("Notification")]), _c('v-tab', [_vm._v("Reward Point")]), _c('v-tab', [_vm._v("Core")]), _c('v-tab', [_vm._v(" Medical Card ")]), _vm.isEmployeeBenefitEnabled ? _c('v-tab', [_vm._v(" Financial ")]) : _vm._e(), _vm.isEmployeeBenefitEnabled ? _c('v-tab', [_vm._v(" Invoice ")]) : _vm._e(), _vm.isEmployeeBenefitEnabled ? _c('v-tab', [_vm._v(" Pricing ")]) : _vm._e()], 1), _c('v-tabs-items', {
    model: {
      value: _vm.dialogTab,
      callback: function callback($$v) {
        _vm.dialogTab = $$v;
      },
      expression: "dialogTab"
    }
  }, [_c('v-tab-item', [_c('v-card-text', _vm._l(_vm.keyOptions, function (category, index) {
    return _c('div', {
      key: index
    }, [category.blacklist ? _c('settings-category', {
      attrs: {
        "category-items": category.blacklist,
        "settings": _vm.distributorSettings,
        "type": category,
        "distributor-id": _vm.distributorId
      }
    }) : _vm._e()], 1);
  }), 0)], 1), _c('v-tab-item', [_c('v-card-text', _vm._l(_vm.keyOptions, function (category, index) {
    return _c('div', {
      key: index
    }, [category.whitelist ? _c('settings-category', {
      attrs: {
        "category-items": category.whitelist,
        "settings": _vm.distributorSettings,
        "type": category,
        "distributor-id": _vm.distributorId
      }
    }) : _vm._e()], 1);
  }), 0)], 1), _c('v-tab-item', [_c('v-card-text', [_vm._v(" ... ")])], 1), _c('v-tab-item', _vm._l(_vm.keyOptions, function (category, index) {
    return _c('div', {
      key: index
    }, [category['reward_point'] ? _c('settings-category', {
      attrs: {
        "category-items": category['reward_point'],
        "settings": _vm.distributorSettings,
        "type": category,
        "distributor-id": _vm.distributorId
      }
    }) : _vm._e()], 1);
  }), 0), _c('v-tab-item', _vm._l(_vm.keyOptions, function (category, index) {
    return _c('div', {
      key: index
    }, [category['core:order'] ? _c('settings-category', {
      attrs: {
        "category-items": category['core:order'],
        "settings": _vm.distributorSettings,
        "type": category,
        "distributor-id": _vm.distributorId
      }
    }) : _vm._e()], 1);
  }), 0), _c('v-tab-item', [_c('v-card-text', _vm._l(_vm.keyOptions, function (category, index) {
    return _c('div', {
      key: index
    }, [category.medical_card ? _c('settings-category', {
      attrs: {
        "category-items": category.medical_card,
        "settings": _vm.distributorSettings,
        "type": category,
        "distributor-id": _vm.distributorId
      }
    }) : _vm._e()], 1);
  }), 0)], 1), _vm.isEmployeeBenefitEnabled ? _c('v-tab-item', _vm._l(_vm.keyOptions, function (category, index) {
    return _c('div', {
      key: index
    }, [category.financial ? _c('settings-category', {
      attrs: {
        "category-items": category.financial,
        "settings": _vm.distributorSettings,
        "type": category,
        "distributor-id": _vm.distributorId,
        "distributor-features": _vm.distributor.features
      }
    }) : _vm._e()], 1);
  }), 0) : _vm._e(), _vm.isEmployeeBenefitEnabled ? _c('v-tab-item', _vm._l(_vm.keyOptions, function (category, index) {
    return _c('div', {
      key: index
    }, [category.invoice ? _c('settings-category', {
      attrs: {
        "category-items": category.invoice,
        "settings": _vm.distributorSettings,
        "type": category,
        "distributor-id": _vm.distributorId
      }
    }) : _vm._e()], 1);
  }), 0) : _vm._e(), _vm.isEmployeeBenefitEnabled ? _c('v-tab-item', _vm._l(_vm.keyOptions, function (category, index) {
    return _c('div', {
      key: index
    }, [category.pricing ? _c('settings-category', {
      attrs: {
        "category-items": category.pricing,
        "settings": _vm.distributorSettings,
        "type": category,
        "distributor-id": _vm.distributorId
      }
    }) : _vm._e()], 1);
  }), 0) : _vm._e()], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v(" Close ")])], 1)], 1)], 1), _vm.distributorSettings ? _c('distributor-tab-settings', {
    attrs: {
      "user-id": _vm.distributorSettings
    },
    on: {
      "openSettingsAside": _vm.setSettingsAside,
      "openEditSettingsAside": _vm.setEditSettingsAside
    }
  }) : _vm._e()], 1), _c('v-tab-item', [_vm.distributorUsage ? _c('product-usage', {
    attrs: {
      "user-id": _vm.distributorUsage
    }
  }) : _vm._e()], 1), _c('v-tab-item', [_vm.distributorReward ? _c('distributor-tab-reward', {
    attrs: {
      "distributor-reward": _vm.distributorReward
    },
    on: {
      "openRewardAside": _vm.setRewardAside,
      "openEditRewardAside": _vm.setEditRewardAside
    }
  }) : _vm._e()], 1)], 1)], 1)], 1)], 1)], 1), _vm.aside ? _c('distributor-avatar-aside', {
    attrs: {
      "id": _vm.distributorId
    },
    on: {
      "changed": function changed($event) {
        _vm.aside = false;
      }
    },
    model: {
      value: _vm.aside,
      callback: function callback($$v) {
        _vm.aside = $$v;
      },
      expression: "aside"
    }
  }) : _vm._e(), _vm.settingsAside ? _c('distributor-settings-aside', {
    attrs: {
      "id": _vm.distributorId
    },
    on: {
      "changed": function changed($event) {
        _vm.loadDistributorSettings();
        _vm.settingsAside = false;
      }
    },
    model: {
      value: _vm.settingsAside,
      callback: function callback($$v) {
        _vm.settingsAside = $$v;
      },
      expression: "settingsAside"
    }
  }) : _vm._e(), _vm.editSettingsAside ? _c('distributor-edit-settings-aside', {
    attrs: {
      "id": _vm.distributorId,
      "resources": _vm.resources
    },
    on: {
      "changed": function changed($event) {
        _vm.loadDistributorSettings(_vm.distributorId);
        _vm.editSettingsAside = false;
      }
    },
    model: {
      value: _vm.editSettingsAside,
      callback: function callback($$v) {
        _vm.editSettingsAside = $$v;
      },
      expression: "editSettingsAside"
    }
  }) : _vm._e(), _vm.rewardAside ? _c('distributor-reward-aside', {
    attrs: {
      "id": _vm.distributorId
    },
    on: {
      "changed": function changed($event) {
        _vm.reloadReward();
        _vm.rewardAside = false;
      }
    },
    model: {
      value: _vm.rewardAside,
      callback: function callback($$v) {
        _vm.rewardAside = $$v;
      },
      expression: "rewardAside"
    }
  }) : _vm._e(), _vm.editRewardAside ? _c('distributor-edit-reward-aside', {
    attrs: {
      "id": _vm.distributorId,
      "resources": _vm.rewardResources
    },
    on: {
      "changed": function changed($event) {
        _vm.reloadReward();
        _vm.editRewardAside = false;
      }
    },
    model: {
      value: _vm.editRewardAside,
      callback: function callback($$v) {
        _vm.editRewardAside = $$v;
      },
      expression: "editRewardAside"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }