var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "900",
      "persistent": "",
      "full-height": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('a', _vm._g(_vm._b({}, 'a', attrs, false), on), [_vm._t("default", function () {
          return [_c('v-icon', {
            staticClass: "me-2",
            attrs: {
              "size": "18"
            }
          }, [_vm._v(" " + _vm._s(_vm.icons.mdiAssistant) + " ")]), _c('span', [_vm._v("Assign Plan")])];
        })], 2)];
      }
    }], null, true),
    model: {
      value: _vm.isDialogVisible,
      callback: function callback($$v) {
        _vm.isDialogVisible = $$v;
      },
      expression: "isDialogVisible"
    }
  }, [_c('claim-plan-owner-form', {
    ref: "formRef",
    attrs: {
      "participants": _vm.participants,
      "loading": _vm.loading,
      "default-value": {
        participant_uuids: _vm.participants.filter(function (ps) {
          return ps.notes.find(function (n) {
            return n.type === 'pay_and_claim';
          });
        }).map(function (p) {
          return p.uuid;
        })
      }
    },
    on: {
      "close": _vm.onClose,
      "submit": _vm.onSubmit
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }