<template>
  <v-form
    ref="formRef"
    @submit.prevent="onSubmit"
  >
    <v-card>
      <v-card-title class="text-sm-h5 d-flex justify-space-between">
        <slot name="title">
          Flow
        </slot>

        <v-icon @click="onClose">
          {{ icons.mdiClose }}
        </v-icon>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <search-user
              :query="{ distributor_id: flow.distributor_id }"
              outlined
              @options="options => userOptions = options"
            />
            <v-select
              v-model="form.user_id"
              :items="userOptions"
              item-text="title"
              item-value="value"
              label="User"
              outlined
              :disabled="disabledFields.includes('user_id') || resource"
            />
          </v-col>

          <v-col cols="12">
            <v-select
              v-model="form.status"
              :items="statusOptions"
              item-text="title"
              item-value="value"
              label="Status"
              outlined
              :disabled="disabledFields.includes('status') || resource"
            />
          </v-col>

          <v-col cols="12">
            Expires At:
            <date-time-picker
              v-model="form.expires_at"
            ></date-time-picker>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          type="submit"
          color="success"
          :loading="loading"
          :disabled="loading"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { now } from '@/@core/utils/filter'
import { SearchUser } from '@/components'
import DateTimePicker from '@/modules/common/components/form/DateTimePicker.vue'
import { t } from '@/plugins/i18n'
import { required } from '@core/utils/validation'
import { mdiClose } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  components: {
    SearchUser,
    DateTimePicker,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default: () => [],
    },
    defaultValue: {
      type: Object,
      default: () => {},
    },
    flow: {
      type: Object,
      required: true,
    },
    resource: {
      type: Object,
      default: null,
    },
  },
  setup(props, { emit }) {
    const isEditing = ref(false)

    const useFieldValue = (fieldName, defaultValue) => (props.resource ? props.resource[fieldName] : (props.defaultValue?.[fieldName] || defaultValue))

    const defaultFormValue = ({
      user_id: useFieldValue('user_id', null),
      status: useFieldValue('status', 'pending'),
      expires_at: useFieldValue('expires_at', now().add(1, 'year')),
    })

    if (defaultFormValue.expires_at) {
      defaultFormValue.expires_at = now(defaultFormValue.expires_at).format('YYYY-MM-DD HH:mm:ss')
    }

    const form = ref({
      ...defaultFormValue,
    })

    const formRef = ref(null)
    const userOptions = ref([])
    const statusOptions = ref([
      { title: 'Pending', value: 'pending' },
      { title: 'Completed', value: 'completed' },
    ])

    const onSubmit = () => {
      if (formRef.value.validate()) {
        emit('submit', form.value)
      }
    }

    const onClose = () => {
      emit('close')
    }

    return {
      isEditing,

      form,
      formRef,

      userOptions,
      statusOptions,

      validators: {
        required,
      },

      t,
      onSubmit,
      onClose,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>
