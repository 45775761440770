<template>
  <div>
    <div
      v-for="participant in userParticipants"
      :key="participant.uuid"
    >
      <b>{{ participant.first_name }}</b>, {{ participant.last_name }} ({{ participant.relationship }})
      <v-chip
        v-for="note in participant.notes"
        :key="note.uuid"
        size="sm"
      >
        {{ note.type }}
      </v-chip>

      <v-icon
        v-if="owner && !owner.participants.find(p => p.participant_uuid === participant.uuid)"
        size="16"
        @click="confirmAddParticipantToOwner(participant, owner).then(() => $emit('updated'))"
      >
        {{ icons.mdiPlus }}
      </v-icon>
    </div>

    <create-participant-dialog
      :key="user.uuid"
      :user="user"
      :notes="[{type: 'pay_and_claim', snapshot: ['first_name', 'last_name', 'relationship']}]"
      @created="forceAddUser(user); $emit('updated')"
    />

    <create-dialog
      v-if="!user.claim_plan_owners.find(owner => owner.claim_plan.uuid === plan.uuid) && userParticipants.length"
      :user="user"
      :plan="plan"
      :participants="userParticipants"
      @updated="$emit('updated')"
    />
  </div>
</template>

<script>
import { useParticipant } from '@/composables'
import CreateParticipantDialog from '@/modules/order/components/participant/CreateDialog.vue'
import { mdiPlus } from '@mdi/js'
import { computed } from '@vue/composition-api'
import useClaimPlan from '../../composables/useClaimPlan'
import CreateDialog from '../plan-owner/CreateDialog.vue'

export default {
  components: { CreateDialog, CreateParticipantDialog },
  props: {
    plan: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const { addUser, forceAddUser, allParticipants } = useParticipant()
    const { confirmAddParticipantToOwner } = useClaimPlan()
    addUser(props.user)

    const userParticipants = computed(() => allParticipants.value.filter(p => p.user.channel_user_id === props.user.uuid))

    const owner = computed(() => props.user.claim_plan_owners.find(owner => owner.claim_plan.uuid === props.plan.uuid))

    return {
      forceAddUser,

      confirmAddParticipantToOwner,

      allParticipants,
      userParticipants,
      owner,
      icons: {
        mdiPlus,
      },
    }
  },
}
</script>
