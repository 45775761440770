var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-alert', {
    staticClass: "mb-2 mt-2",
    attrs: {
      "dense": "",
      "text": "",
      "type": "info"
    }
  }, [_vm._v(" This Selection allows users to choose "), _c('b', [_vm._v(_vm._s(_vm.selection.min_option))]), _vm.selection.max_option > _vm.selection.min_option ? _c('span', [_vm._v("to "), _c('b', [_vm._v(_vm._s(_vm.selection.max_option))])]) : _vm._e(), _vm._v(" Option(s) ")]), _c('create-dialog', {
    attrs: {
      "selection": _vm.selection
    },
    on: {
      "created": _vm.tableList.paginate
    }
  }, [_c('v-btn', [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiPlus) + " ")]), _vm._v(" Create Option ")], 1)], 1), _c('claim-plan-selection-option-table', {
    ref: "tableRef",
    staticClass: "mt-2",
    attrs: {
      "table-list": _vm.tableList
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }