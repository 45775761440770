var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "900",
      "persistent": "",
      "full-height": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('div', _vm._g(_vm._b({}, 'div', attrs, false), on), [_vm._t("default", function () {
          return [_c('v-icon', {
            staticClass: "me-2",
            attrs: {
              "size": "18"
            }
          }, [_vm._v(" " + _vm._s(_vm.icons.mdiAccountEdit) + " ")]), _c('span', [_vm._v("Edit")])];
        })], 2)];
      }
    }], null, true),
    model: {
      value: _vm.isDialogVisible,
      callback: function callback($$v) {
        _vm.isDialogVisible = $$v;
      },
      expression: "isDialogVisible"
    }
  }, [_c('user-form', {
    ref: "formRef",
    attrs: {
      "user": _vm.user,
      "loading": _vm.loading
    },
    on: {
      "close": _vm.onClose,
      "submit": _vm.onSubmit
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', [_vm._v("Edit Distributor User")])];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }