<template>
  <div>
    <h3 class="mt-4 mb-2 ml-4 mr-4">
      You can add actions for user to finish
    </h3>

    <create-dialog
      :flow="flow"
      @created="paginate"
    />

    <action-table
      :table-list="tableList"
    />
  </div>
</template>

<script>
import { useTableList } from '@/composables'
import { ref } from '@vue/composition-api'
import ActionTable from '../../components/action/ActionTable.vue'
import CreateDialog from '../../components/action/CreateDialog.vue'
import { actionService } from '../../services'

export default {
  components: {
    ActionTable,
    CreateDialog,
  },
  props: {
    flow: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const filters = ref({
      flow_id: props.flow.id,

    //   distributor_id: null,
    })

    const tableList = useTableList(actionService, filters, {
      options: {
        sortBy: ['sort'],
        sortDesc: [false],
      },
    })

    const { paginate } = tableList

    return {
      tableList,
      paginate,
    }
  },
}
</script>
