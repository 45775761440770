<template>
  <v-form ref="formElem">
    <v-card>
      <translation-locales />

      <v-card-text>
        <v-select
          v-if="!resource"
          v-model="form.category"
          :items="typeOptions"
          item-text="name"
          item-value="value"
          label="Select Type"
          class="mt-5"
          outlined
          hide-details
          :disabled="!!resource"
        ></v-select>

        <v-text-field
          v-model="form.key"
          label="Unique Key"
          class="mt-5"
          outlined
          :rules="[required]"
          :disabled="!!resource"
        />

        <v-autocomplete
          v-if="form.category !== 'alias'"
          v-model="form.distributor_id"
          :items="distributorOptions"
          item-text="title"
          item-value="value"
          label="Select Distributor"
          outlined
          hide-details
          :disabled="!!resource"
        ></v-autocomplete>

        <v-autocomplete
          v-if="form.category !== 'alias'"
          v-model="form.alias_id"
          class="mt-4 mb-4"
          :items="dictionaryOptions"
          item-text="title"
          item-value="value"
          label="(Optional) Alias e.g. alias to GP, submit an extra child from GP."
          outlined
          hide-details
          clearable
        ></v-autocomplete>

        <translatable-editor
          v-if="useTranslatableEditor"
          v-model="form.value"
          label="Value"
          class="mt-5"
          allow-empty
        />

        <translatable-input
          v-else
          v-model="form.value"
          label="Value"
          class="mt-5"
          allow-empty
        />

        <v-text-field
          v-model="form.notes"
          label="Notes"
          outlined
          class="mt-5"
        />
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { TranslatableEditor, TranslatableInput, TranslationLocales } from '@/components'
import { useDistributor, useTranslatable } from '@/composables'
import { required } from '@core/utils/validation'
import { computed, ref } from '@vue/composition-api'
import useDictionaries from '../useDictionaries'

export default {
  components: {
    TranslatableInput,
    TranslatableEditor,
    TranslationLocales,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    // Get value from resource prop, use initValue if not exist
    const field = (name, initValue) => ({
      [name]: props.resource ? props.resource[name] : initValue,
    })

    const { distributorOptions } = useDistributor()
    const { dictionaryOptions, loadDictionaries } = useDictionaries()
    loadDictionaries({
      category: 'alias',
      take: 999,
    })

    const initialForm = {
      ...field('key', ''),
      ...field('value', useTranslatable(null)),
      ...field('distributor_id', null),
      ...field('alias_id', null),
      ...field('category', null),
      ...field('notes', null),
    }

    const form = ref({
      ...initialForm,
    })
    const formElem = ref(null)

    const typeOptions = ref([
      // {
      //   name: 'App Required',
      //   value: 'app_required',
      // },
      {
        name: 'Receipt Type',
        value: 'receipt_type',
      },
      {
        name: 'Claim Item Type',
        value: 'claim_item_type',
      },
      {
        name: 'Alias',
        value: 'alias',
      },

      // {
      //   name: 'alternative',
      //   value: 'alternative',
      // },
      // {
      //   name: 'Custom',
      //   value: 'custom',
      // },
    ])

    const validate = () => {
      if (formElem.value.validate()) emit('submit', form.value)
    }

    const useTranslatableEditor = computed(() => form.value.key.includes('html') || form.value.key.includes('formatted'))

    return {
      form,
      formElem,
      validate,
      useTranslatableEditor,
      required,
      distributorOptions,
      dictionaryOptions,
      typeOptions,
    }
  },
}
</script>
