<template>
  <div>
    <h3 class="mt-4 mb-2 ml-4 mr-4">
      You can track, finish, start, enable a user's process here.
    </h3>

    <create-dialog
      :flow="flow"
      @created="tableList.paginate"
    />

    <flow-process-table
      :table-list="tableList"
    />
  </div>
</template>

<script>
import { useTableList } from '@/composables'
import { ref } from '@vue/composition-api'
import CreateDialog from '../../components/process/CreateDialog.vue'
import FlowProcessTable from '../../components/process/FlowProcessTable.vue'
import { flowProcessService } from '../../services'

export default {
  components: {
    FlowProcessTable,
    CreateDialog,
  },
  props: {
    flow: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const filters = ref({
      flow_id: props.flow.id,
    })

    const tableList = useTableList(flowProcessService, filters)

    return {
      tableList,
    }
  },
}
</script>
