<template>
  <div>
    <!-- Filter -->
    <v-card>
      <v-card-text class="d-flex justify-space-between">
        <div>
          <v-btn
            color="primary"
            :loading="loading"
            :disabled="loading"
            @click="fetchData"
          >
            <v-icon class="prefix-icon">
              {{ icons.mdiRefresh }}
            </v-icon>
            <span>
              {{ $t('common.list.refresh') }}
            </span>
          </v-btn>
        </div>

        <div>
          <create-dialog
            v-if="$can('user:create', 'user_management')"
            @created="fetchData"
          />
        </div>
      </v-card-text>

      <role-table
        ref="tableRef"
        :loading="loading"
        :roles="roles"
        :roles-total="rolesTotal"
        @updated="fetchData"
        @options-updated="onOptionsUpdated"
      />
    </v-card>
  </div>
</template>

<script>
import {
  mdiDeleteOutline, mdiDotsVertical, mdiMenuDown, mdiMenuUp, mdiPencilOutline,
} from '@mdi/js'

import { t } from '@/plugins/i18n'
import { formatDate } from '@core/utils/filter'
import { ref } from '@vue/composition-api'

import { useOldDataFetch, useOldTableList } from '@/composables'
import CreateDialog from '@/modules/user/components/role/CreateDialog.vue'
import RoleTable from '@/modules/user/components/role/RoleTable.vue'
import { roleService } from '../services'

export default {
  components: {
    RoleTable,
    CreateDialog,
  },
  setup() {
    const { parseParam } = useOldDataFetch()
    const { loading, tableRef, mergeOptionToParams } = useOldTableList()

    const roles = ref([])
    const rolesTotal = ref(0)

    const filters = ref({
      search: null,
    })

    const params = ref({
      ...filters.value,
    })

    const fetchData = async () => {
      loading.value = true

      try {
        const { records, pagination } = await roleService.getRoles(parseParam(params.value))

        roles.value = records
        rolesTotal.value = pagination.total
      } catch (error) {
        console.log(error)
      } finally {
        loading.value = false
      }
    }

    const onFilterUpdated = value => {
      params.value = {
        ...params.value,
        ...value,
      }

      fetchData()
    }

    const onOptionsUpdated = options => {
      params.value = mergeOptionToParams(options, params.value)

      fetchData()
    }

    return {
      t,
      formatDate,

      loading,

      roles,
      rolesTotal,

      tableRef,

      fetchData,
      onFilterUpdated,
      onOptionsUpdated,

      icons: {
        mdiDotsVertical,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiMenuDown,
        mdiMenuUp,
      },
    }
  },
}
</script>

<style lang="scss" scoped></style>
