<template>
  <div>
    <create-dialog
      :upload-path="`claim-plan-selections/${selection.id}/photo`"
      @created="$emit('updated')"
    />

    <div
      v-for="photo in selection.photos"
      :key="photo.id"
      class="mb-2 mt-2"
    >
      <span class="mr-2">#{{ photo.id }}</span>

      <img
        :src="photo.thumbnail"
        alt=""
        class="img img-thumbnail"
      >

      <v-icon
        v-if="$can('claim_plan_selection_photo:delete')"
        size="16"
        @click="confirmDeletePhoto(photo).then(() => $emit('updated'))"
      >
        {{ icons.mdiTrashCan }}
      </v-icon>
    </div>
  </div>
</template>

<script>
import { usePhoto } from '@/composables';
import CreateDialog from '@/modules/media/components/photo/CreateDialog.vue';
import { mdiTrashCan } from '@mdi/js';

export default {
  components: { CreateDialog },
  props: {
    selection: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { confirmDeletePhoto } = usePhoto()

    return {
      confirmDeletePhoto,
      icons: {
        mdiTrashCan,
      },
    }
  },
}
</script>
