<template>
  <v-dialog
    v-model="isDialogVisible"
    max-width="800"
    persistent
    full-height
  >
    <!-- Dialog Activator -->
    <template #activator="{ on, attrs }">
      <v-btn
        color="success"
        outlined
        class="mb-1 me-3"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon
          size="17"
          class="me-1"
        >
          {{ icons.mdiPlus }}
        </v-icon>
        <span>Create</span>
      </v-btn>
    </template>

    <!-- Dialog Content -->
    <team-form
      ref="formRef"
      :loading="loading"
      :disabled-fields="disabledFields"
      @close="onClose"
      @submit="onSubmit"
    >
      <template #title>
        {{ $t('Create Team') }}
      </template>
    </team-form>
  </v-dialog>
</template>

<script>
import { useNotifyErrors, useNotifySuccess } from '@/composables'
import { mdiPlus, mdiRefresh } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { teamService } from '../../services'
import TeamForm from './TeamForm.vue'

export default {
  components: {
    TeamForm,
  },
  props: {
    distributorId: {
      type: Number,
      default: null,
    },
    disabledFields: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const formRef = ref(null)
    const isDialogVisible = ref(false)
    const loading = ref(false)

    const onClose = () => {
      isDialogVisible.value = false
    }

    const onSubmit = async data => {
      try {
        loading.value = true

        await teamService.store({
          ...data,
          distributor_id: data.distributor_id || props.distributorId,
        })

        isDialogVisible.value = false

        emit('created')

        useNotifySuccess({
          content: 'Distributor team has been created successfully.',
        })
      } catch (error) {
        console.log(error)

        useNotifyErrors(error)
      } finally {
        loading.value = false
      }
    }

    return {
      formRef,
      isDialogVisible,
      loading,

      onClose,
      onSubmit,

      icons: {
        mdiPlus,
        mdiRefresh,
      },
    }
  },
}
</script>
