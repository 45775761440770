<template>
  <div v-if="action">
    {{ action.content }}
  </div>
</template>

<script>
export default {
  props: {
    action: {
      type: Object,
      default: () => null,
    },
  },
}
</script>
