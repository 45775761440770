import axios from '@axiosV2'

const index = params =>
  axios.get('/admin/documents', {
    params,
  })

// const show = id => axios.get(`/admin/documents/${id}`)
// const store = data => axios.post('/admin/documents', data)

const storeByPath = (path, data) => {
  const formData = new FormData()

  Object.keys(data).forEach(key => {
    formData.append(key, data[key])
  })

  return axios.post(`/admin/${path}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

// const update = (id, data) => axios.put(`/admin/documents/${id}`, data)
const destroy = id => axios.delete(`/admin/documents/${id}`)

export default {
  destroy,

  //   show,
  index,

  //   store,
  storeByPath,

  //   update,
}
