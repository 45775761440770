import { mdiStepForward } from '@mdi/js'

export default [
  {
    subheader: 'Flow Management',
  },
  {
    title: 'Flow',
    icon: mdiStepForward,
    to: 'flows',
  },
]
