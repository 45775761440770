var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "800",
      "persistent": "",
      "full-height": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "color": "success",
            "outlined": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          staticClass: "me-1",
          attrs: {
            "size": "17"
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiPlus) + " ")]), _c('span', [_vm._v("Create Action")])], 1)];
      }
    }]),
    model: {
      value: _vm.isDialogVisible,
      callback: function callback($$v) {
        _vm.isDialogVisible = $$v;
      },
      expression: "isDialogVisible"
    }
  }, [_c('action-form', {
    attrs: {
      "loading": _vm.loading,
      "disabled-fields": _vm.disabledFields
    },
    on: {
      "close": _vm.onClose,
      "submit": _vm.onSubmit
    },
    scopedSlots: _vm._u([{
      key: "form-plugin",
      fn: function fn() {
        return [_c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('v-autocomplete', {
          attrs: {
            "items": _vm.selectionOptions,
            "item-text": "title",
            "item-value": "value",
            "label": "Select a Claim Plan",
            "outlined": ""
          },
          model: {
            value: _vm.form.actionable_id,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "actionable_id", $$v);
            },
            expression: "form.actionable_id"
          }
        })], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }